/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
function DefaultProjectCard({ image, label, title, description, authors, info, date }) {
  const labels = [];
  const values = [];
  Object.keys(info).forEach((el) => {
    let label = el;
    let value = info[el];
    if (el === "likes") {
      label = <FavoriteIcon fontSize="small" />;
    } else if (el === "comments") {
      label = <CommentIcon fontSize="small" />;
    }
    labels.push(label);
    values.push(value);
    // }
  });
  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  const renderAuthors = authors?.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <MDAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        // boxShadow: "none",
        overflow: "visible",
        paddingBottom: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <MDBox position="relative" width="100.25%" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            maxWidth: "100%",
            width: "300px",
            height: "100px",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            // objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </MDBox>
      <MDBox mt={1} mx={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {description}
        </MDTypography>
        <MDBox lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {label}
          </MDTypography>
        </MDBox>
        <MDBox lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {date}
          </MDTypography>
        </MDBox>
        <MDBox display="flex">{renderItems}</MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
  info: PropTypes.objectOf(PropTypes.number).isRequired,
  date: PropTypes.string,
};

export default DefaultProjectCard;
