// @mui material components
import { Grid, Icon, Menu, MenuItem, Tooltip } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//react
import "react-data-table-component-extensions/dist/index.css";
import "react-data-table-component-extensions/dist/index.css";

// Data
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types"; // Import PropTypes
import { Fragment, useEffect, useState } from "react";
import GroupService from "Service/Group/GroupService";
import "./table.css";

import noimage from "../../assets/images/noimagepng.png";

import { Badge, Breadcrumb, Button, Card, Col, Row, Modal, OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import user from "../../assets/images/output.png";
import GruopModel from "./EditGroup";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { toast } from "react-toastify";
import BaseURL from "Baseurl";
import MakeMember from "./MakeMember";

import { styled } from "@mui/system";
import { tablePaginationClasses as classes } from "@mui/base/TablePagination";
import TablePagination from "@mui/base/TablePagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Pagination } from "antd";
function GroupDetails() {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [totalPages, setTotalPages] = useState(0);
  const isMdScreenAndUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const handlePageChange = (page, pageSize) => {
    console.log(`Page changed to: ${page}, PageSize: ${pageSize}`);
    setCurrentPage(page);
    // Perform any other actions you need on page change
  };
  const handlePageSizeChange = (current, size) => {
    console.log(`Page size changed to: ${size}, Current page: ${current}`);
    setPageSize(size);
    // Perform any other actions you need on page size change
  };
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Make sure to adjust the type as needed
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  Job.propTypes = {
    title: PropTypes.string.isRequired, // Make sure to adjust the type as needed
  };
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [groupdata, setGroupData] = useState(null);
  const [opencategory, setopenCategory] = useState(false);
  const [istrue, setIsTrue] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [userIds, setUserIds] = useState(null);

  const [admins, setAdmins] = useState(null);

  console.log("admin", admins);

  const handleClick = (event, userId, admin) => {
    setUserIds(userId);
    setAdmins(admin);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDrop = () => {
    setAnchorEl(null);
  };
  const handleVerify = () => {
    setAnchorEl(null);
    setVerifyConfirmModal(true);
  };

  const getUsers = async () => {
    try {
      const response = await GroupService.get(id).then((res) => {
        console.log("res", res.data);
        setGroupData(res.data.data);
      });
      if (response?.data && response?.data?.length > 0) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  //make admin
  const handleMakeAdmin = async () => {
    try {
      let response = await BaseURL.put(`/admin/Group/makeAdmin/${id}?user_id=${userIds}`);
      if (response) {
        getmember();
        toast.success(response.data.message);
        setAnchorEl(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveAdmin = async () => {
    try {
      let response = await BaseURL.put(`/admin/Group/removeAdmin/${id}?user_id=${userIds}`);
      if (response) {
        getmember();
        toast.success(response.data.message);
        setAnchorEl(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //make member

  const handleRemoveMember = async () => {
    try {
      let response = await BaseURL.get(
        `/admin/Group/remove_member?groupId=${id}&userId=${userIds}`
      );
      if (response) {
        getmember();
        getUsers();
        toast.success(response.data.message);
        setAnchorEl(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //end

  const [memberData, setMemberData] = useState([]);
  const handleSearchChange = (e) => {
    setCurrentPage(1);
    setSearchText(e);
  };
  const getmember = async () => {
    try {
      const response = await BaseURL.get(`admin/Group/memberList/${id}`, {
        params: {
          limit: pageSize,
          page: currentPage,
          searchKey: searchText,
        },
      }).then((res) => {
        setMemberData(res.data.data);
        setTotalPages(res.data.total_count);
      });
      if (response?.data && response?.data?.length > 0) {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickCategory = (item) => {
    console.log("data", item);
    setopenCategory(true);
    setGroupData(item);
    setIsTrue(true);
  };
  const handleDelete = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Group?",
        text: "Are you sure you want to delete this Group?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`admin/Group/${id}`, {
              id: id,
            }).then((res) => {
              getUsers();
              console.log("res", res.data);
            });
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Group has been deleted successfully.",
            "success"
          );
        }
      });
  };
  useEffect(() => {
    getmember();
  }, [currentPage, searchText, pageSize]);
  console.log(memberData.length, "memberData");

  const [openmember, setOpenMember] = useState(false);

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
  };
  const handlemember = () => {
    setOpenMember(true);
  };
  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div
                    className="group"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <MDTypography variant="h6" color="white">
                      Group Details
                    </MDTypography>
                  </div>
                </MDBox>
                <div className="group_details p-5 row">
                  <div className="col-md-3">
                    <div className="cover_image">
                      {groupdata?.cover_image ? (
                        <img
                          src={`${baseURL}${groupdata?.cover_image}`}
                          alt="image"
                          height="180px"
                          width="180px"
                        />
                      ) : (
                        <img src={noimage} alt="image" width="100px" height="100px" />
                      )}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="group_det">
                      <p>Name</p> :<span>{groupdata?.name}</span>
                    </div>
                    <div className="group_det">
                      <p>Group Type</p> :<span>{groupdata?.group_type}</span>
                    </div>
                    <div className="group_det">
                      <p>About</p> :<span>{groupdata?.about}</span>
                    </div>
                    <div className="group_det">
                      <p>No of Members</p> :<span>{groupdata?.no_of_members}</span>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <Tooltip placement="top">
                      <Icon onClick={() => handleClickCategory(groupdata)}>edit</Icon>
                    </Tooltip>
                    <Tooltip placement="top">
                      <Icon onClick={() => handleDelete(id)}>delete</Icon>
                    </Tooltip>
                  </div>
                  <div className="col-md-12 mt-5">
                    <div className="group-member">
                      <p>
                        Member List
                        <span onClick={handlemember}>Add Member</span>
                      </p>
                      {!memberData.length > 0 ? (
                        <span>Not Found</span>
                      ) : (
                        <ul>
                          <input
                            type="text"
                            placeholder="Search..."
                            onChange={(e) => handleSearchChange(e.target.value)}
                            style={{
                              margin: "1rem",
                              backgroundColor: "transparent",
                              border: "1px solid #e8e8f7",
                              borderRadius: "7px",
                              /* margin-left: -11px!important; */
                              outline: "none",
                              padding: "0.375rem 0.75rem",
                              height: "40px",
                              fontSize: "18px",
                            }}
                          />
                          {memberData.map((itm) => {
                            const userId = itm?.userDetails?.id;
                            const admin = itm.is_admin;
                            return (
                              <li key={itm?.id}>
                                <div className="detail_mem justify-content-between">
                                  <Link to={`/users/${itm?.userDetails?.id}`}>
                                    {itm?.userDetails?.profile_pic ? (
                                      <img
                                        src={`${baseURL}${itm?.userDetails?.profile_pic}`}
                                        width="80px"
                                        height="80px"
                                        alt="profile"
                                      />
                                    ) : (
                                      <img src={user} width="80px" height="80px" alt="profile" />
                                    )}
                                    <span style={{ paddingLeft: "10px" }}>
                                      {itm?.userDetails?.name}
                                    </span>
                                    <span
                                      style={{ fontSize: "15px", color: "red", marginLeft: "10px" }}
                                    >
                                      {itm?.is_admin ? "(Admin)" : ""}
                                    </span>
                                  </Link>

                                  <MDTypography component={Link} variant="body2" color="secondary">
                                    <MoreHorizIcon
                                      id="demo-positioned-button"
                                      aria-controls={open ? "demo-positioned-menu" : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                      onClick={(event) => handleClick(event, userId, admin)}
                                      fontSize="medium"
                                    >
                                      Edit
                                    </MoreHorizIcon>
                                    <Menu
                                      id="demo-positioned-menu"
                                      aria-labelledby="demo-positioned-button"
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleCloseDrop}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      style={{ paddingRight: "40px" }}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          admins
                                            ? handleRemoveAdmin(userId)
                                            : handleMakeAdmin(userId);
                                        }}
                                      >
                                        {admins ? "Remove Admin" : "Make Admin"}
                                      </MenuItem>
                                      <p>{admin}</p>
                                      <MenuItem
                                        onClick={() => {
                                          handleRemoveMember(userId);
                                        }}
                                      >
                                        Romove Member
                                      </MenuItem>
                                    </Menu>
                                  </MDTypography>
                                </div>
                              </li>
                            );
                          })}
                          <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            total={totalPages}
                            onChange={handlePageChange}
                            onShowSizeChange={handlePageSizeChange}
                            showSizeChanger
                            pageSizeOptions={["5", "20", "30", "40"]}
                          />
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <GruopModel
        opens={opencategory}
        data={groupdata}
        isTrue={istrue}
        id={groupdata?.id}
        onUpdate={() => getUsers()}
        onClose={() => setopenCategory(false)}
      />
      <MakeMember
        opens={openmember}
        id={groupdata?.id}
        onUpdate={() => getUsers()}
        onUpdates={() => getmember()}
        onClose={() => setOpenMember(false)}
      />
    </Fragment>
  );
}

export default GroupDetails;
