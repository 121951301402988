import BaseURL from "../Baseurl";

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const res = await BaseURL.post("/User/change_password", {
      old_password: oldPassword,
      new_password: newPassword,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getAdminProfile = async (id) => {
  try {
    const { data } = await BaseURL.get(`admin/User/user_details/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const changeEmail = async (email) => {
  try {
    const res = await BaseURL.post(`admin/User/UpdateMail?email=${email}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
