/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BaseURL from "Baseurl";
import { useNavigate } from "react-router-dom";
import ResetPassword from "./Resetpassword";

function Cover() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");

  const [resetPassword, setPassword] = useState(false);

  const reserPassword = () => {
    setPassword(true);
    handleSubmit();
  };

  const handleSubmit = (e) => {
    setPassword(true);
    console.log("i m");
    e.preventDefault();
    BaseURL.get(`/User/forgotPassword/${email}`)
      .then((response) => {
        setEmail("");
        if (response.data) {
          toast.success("Forgot password Success");
          setPassword(true);
        }
        console.log(response.data);
      })
      .catch((err) => {
        toast.error(err.response.message);
      });
  };

  return (
    <Fragment>
      {!resetPassword ? (
        <CoverLayout coverHeight="50vh" image={bgImage}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="error"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Forgot Password
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                You will receive an e-mail in maximum 60 seconds
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form" onSubmit={handleSubmit}>
                <MDBox mb={4}>
                  <MDInput
                    type="email"
                    label="Email"
                    variant="standard"
                    fullWidth
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </MDBox>
                <MDBox mt={6} mb={1}>
                  <MDButton variant="gradient" type="submit" color="error" fullWidth>
                    <span>Submit</span>
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </CoverLayout>
      ) : (
        <ResetPassword />
      )}
    </Fragment>
  );
}

export default Cover;
