// @mui material components
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types"; // Import PropTypes
import { useEffect, useState } from "react";
import { getAllUsers } from "Service/UserService";
import team2 from "assets/images/team-2.jpg";
import { CircularProgress } from "@mui/material";
import { Link, useNavigate, useHistory, NavLink, Navigate, useLocation } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BaseURL from "Baseurl";
import user from "../../assets/images/output.png";

import {
  Badge,
  Breadcrumb,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
function Users() {
  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Make sure to adjust the type as needed
    name: PropTypes.string.isRequired,
    // email: PropTypes.string.isRequired,
  };
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  Job.propTypes = {
    title: PropTypes.string.isRequired, // Make sure to adjust the type as needed
  };
  // const navigate = useNavigate();
  const [usersList, setUsersList] = useState();
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageLimit, setPerPage] = useState(10);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1
  );
  const [pageLimit, setPerPage] = useState(
    parseInt(searchParams.get("limit")) ? parseInt(searchParams.get("limit")) : 10
  );

  let userType = "normal";

  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const getUsers = async () => {
    try {
      const response = await getAllUsers(currentPage, pageLimit, searchText, userType);
      if (response?.data && response?.data?.length > 0) {
        setUsersList(response?.data);
        let list = response?.data?.map((item) => item);
        setData(list);
        setTotalRows(response?.total_count);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (user) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete User?",
        text: "Are you sure you want to delete this User?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`admin/User/${user.id}`, {
              id: user?.id,
            }).then((res) => {
              getUsers();
              window.location.reload(true);
              console.log("res", res.data);
            });
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "User has been deleted successfully.",
            "success"
          );
        }
      });
  };

  const columns = [
    {
      name: "User",
      selector: (row) => [row?.name],
      cell: (row) => <div onClick={() => redirectToUser(row.id)}>{row?.name}</div>,
    },
    {
      name: "Profile Pic",
      selector: (row) => [row?.profile_pic],
      cell: (row) => (
        <div>
          {row?.profile_pic ? (
            <img
              src={`${process.env.REACT_APP_BASE_URL}${row?.profile_pic}`}
              width="80px"
              height="80px"
              style={{ objectFit: "cover" }}
            />
          ) : (
            <img src={user} width="80px" height="80px" />
          )}
        </div>
      ),
    },
    {
      name: "Position",
      selector: (row) => [row.position],
      cell: (row) => <div>{row?.position}</div>,
    },
    {
      name: "Organization",
      selector: (row) => [row.organaization],
      cell: (row) => <div>{row?.organaization}</div>,
    },
    {
      name: "Country",
      selector: (row) => [row?.country],
      cell: (row) => <div>{row?.country}</div>,
    },
    {
      name: "ACTIONS",
      // selector: (row) => [row.ACTIONS],

      cell: (row) => (
        <div className="button-list">
          <OverlayTrigger overlay={<Tooltip> Delete</Tooltip>}>
            <DeleteIcon
              onClick={() => handleDelete(row)}
              sx={{ fontSize: 30, cursor: "pointer" }}
            />
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Created",
      selector: (row) => [row.start_date],
      cell: (row) => <div>{row.start_date ? moment(row.start_date).format("LL") : ""}</div>,
    },
  ];

  const tableData = {
    columns,
    data,
  };
  useEffect(() => {
    getUsers();
  }, [currentPage, pageLimit, searchText, userType]);
  const handleRowClicked = (row) => {
    data.map((item) => {
      if (row.id !== item.id) {
        return item;
      }
      navigate(`/users/${item?.id}`);
    });
  };
  const handleSearchChange = (e) => {
    setCurrentPage(1);
    setSearchText(e);
  };
  // useEffect(() => {
  //   // Apply filtering logic here
  //   const filtered = data.filter((item) => {
  //     // Replace 'fieldName' with the actual field you want to filter on
  //     return item.fieldName.toLowerCase().includes(searchText.toLowerCase());
  //   });

  //   // Set the filtered data
  //   setFilteredData(filtered);
  // }, [data, searchText]);
  if (loading) {
    return (
      <CircularProgress
        size={60} // Adjust the size here
        style={{
          margin: "auto",
          display: "block",
          color: "#1A73E8",
          position: "relative",
          zIndex: 1,
          top: "25rem",
        }} // Center horizontally
      />
    );
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Users Table
                  </MDTypography>
                </MDBox>
                {/* <DataTableExtensions {...tableData}> */}
                <div>
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => handleSearchChange(e.target.value)}
                    style={{
                      margin: "1rem",
                      backgroundColor: "transparent",
                      border: "1px solid #e8e8f7",
                      borderRadius: "7px",
                      /* margin-left: -11px!important; */
                      outline: "none",
                      padding: "0.375rem 0.75rem",
                      height: "40px",
                      fontSize: "18px",
                    }}
                  />
                  <DataTable
                    data={data}
                    columns={columns}
                    paginationServer={true}
                    pagination={true}
                    paginator
                    rows={1}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={pageLimit}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={(p) => {
                      setPerPage(p);
                      setCurrentPage(1);
                    }}
                    onChangePage={(p) => setCurrentPage(p)}
                    onRowClicked={handleRowClicked}
                  />
                </div>
                {/* </DataTableExtensions> */}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

function redirectToUser(id) {
  window.location = `/users/${id}`;
  // const navigate = useNavigate();
  // navigate(`/users/${id}`, { replace: true });
}

export default Users;
export { redirectToUser };
