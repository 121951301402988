// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//react
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "react-data-table-component-extensions/dist/index.css";

// Data
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types"; // Import PropTypes
import { Fragment, useEffect, useState } from "react";

import ArticleService from "Service/Article/ArticleService";

import team2 from "assets/images/team-2.jpg";
import { useNavigate } from "react-router-dom";
import { Image } from "@mui/icons-material";
import moment from "moment";
import BaseURL from "Baseurl";
import ModelArticle from "./ModelArticle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "../tables/table.css";
import { useLocation } from "react-router-dom";

import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { redirectToUser } from "layouts/users";
import { CircularProgress } from "@mui/material";

function Article() {
  const [totalRows, setTotalRows] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1
  );
  const [pageLimit, setPerPage] = useState(
    parseInt(searchParams.get("limit")) ? parseInt(searchParams.get("limit")) : 10
  );
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Make sure to adjust the type as needed
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  Job.propTypes = {
    title: PropTypes.string.isRequired, // Make sure to adjust the type as needed
  };
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [data, setData] = useState([]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await ArticleService.getAll({
        limit: pageLimit,
        page: currentPage,
      }).then((res) => {
        console.log("res", res.data);
        let group = res.data.data.map((itm) => itm);
        setData(group);
        setTotalRows(res.data.total_count);
      });
      if (response?.data && response?.data?.length > 0) {
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, pageLimit]);

  const handleDelete = (row) => {
    console.log("delete_id", row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Article?",
        text: "Are you sure you want to delete this Article?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`admin/Article/${row.id}`, {
              id: row?.id,
            }).then((res) => {
              getUsers();
              console.log("res", res.data);
            });
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Article has been deleted successfully.",
            "success"
          );
        }
      });
  };

  const columns = [
    {
      name: "Cover Image",
      selector: (row) => [row?.coverImage],
      cell: (row) => (
        <div>
          <img
            src={`${baseURL}${row?.coverImage}`}
            width="80px"
            height="80px"
            style={{ objectFit: "cover" }}
          />
        </div>
      ),
    },
    {
      name: "User",
      selector: (row) => [row?.userDetails?.name],
      cell: (row) => (
        <div onClick={() => redirectToUser(row?.userDetails?.id)}>{row?.userDetails?.name}</div>
      ),
    },
    {
      name: "Location",
      selector: (row) => [row?.location],
      cell: (row) => <div>{row?.location}</div>,
    },
    {
      name: "No Of Comments",
      selector: (row) => [row?.noOfComments],
      cell: (row) => <div>{row?.noOfComments}</div>,
    },

    {
      name: "No Of Likes",
      selector: (row) => [row?.noOfLikes],
      cell: (row) => <div>{row?.noOfLikes}</div>,
    },

    {
      name: "No Of Views",
      selector: (row) => [row.noOfViews],
      cell: (row) => <div>{row?.noOfViews}</div>,
    },
    {
      name: "Title",
      selector: (row) => [row.title],
      cell: (row) => <div>{row?.title}</div>,
    },
    {
      name: "ACTIONS",
      selector: (row) => [row.ACTIONS],

      cell: (row) => (
        <div className="button-list">
          <OverlayTrigger overlay={<Tooltip> Edit</Tooltip>}>
            <EditIcon
              onClick={() => handleClickCategory(row)}
              sx={{ fontSize: 30, marginLeft: "20px" }}
            />
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip> Delete</Tooltip>}>
            <DeleteIcon onClick={() => handleDelete(row)} sx={{ fontSize: 30 }} />
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Created",
      selector: (row) => [row.date],
      cell: (row) => <div>{moment(row.date).format("LL")}</div>,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const handleRowClick = (rowData) => {
    console.log("Clicked row data:", rowData);
    navigate(`/users/${rowData?.id}`);
    // Now you can access the details of the clicked row in rowData
  };

  const [opencategory, setopenCategory] = useState(false);
  const [istrue, setIsTrue] = useState("");
  const [groupData, setGroupData] = useState(null);

  const handleClickCategory = (item) => {
    console.log("data", item);
    setopenCategory(true);
    setGroupData(item);
    setIsTrue(true);
  };

  const [opengroup, setOpenGroup] = useState(false);

  const handleCreate = () => {
    setOpenGroup(true);
  };

  const redirectToArticle = (data) => {
    window.location = `/article/${data.id}`;
  };

  if (loading) {
    return (
      <CircularProgress
        size={60} // Adjust the size here
        style={{
          margin: "auto",
          display: "block",
          color: "#1A73E8",
          position: "relative",
          top: "20rem",
        }}
      />
    );
  } else {
    return (
      <Fragment>
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <div
                      className="group"
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <MDTypography variant="h6" color="white">
                        Article Table
                      </MDTypography>

                      <MDTypography
                        variant="h6"
                        color="white"
                        onClick={handleCreate}
                        className="add"
                        style={{
                          border: "1px solid white",
                          padding: "4px 10px",
                          ccursor: "pointer",
                        }}
                      >
                        Add Article
                      </MDTypography>
                    </div>
                  </MDBox>
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      data={data}
                      columns={columns}
                      paginationServer={true}
                      pagination={true}
                      paginationDefaultPage={currentPage}
                      paginationPerPage={pageLimit}
                      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={(p) => {
                        setPerPage(p);
                        setCurrentPage(1);
                      }}
                      onChangePage={(p) => setCurrentPage(p)}
                      onRowClicked={(e) => redirectToArticle(e)}
                    />
                  </DataTableExtensions>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
        <ModelArticle
          opens={opencategory}
          data={groupData}
          isTrue={istrue}
          id={groupData?.id}
          onUpdate={() => getUsers()}
          onClose={() => setopenCategory(false)}
        />
        <ModelArticle
          opens={opengroup}
          onUpdate={() => getUsers()}
          onClose={() => setOpenGroup(false)}
        />
      </Fragment>
    );
  }
}

export default Article;
