import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Badge, Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import GroupService from "Service/Group/GroupService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsers } from "Service/UserService";
import { createPosts } from "Service/PostServices";
import { EditPosts } from "Service/PostServices";
import Select from "react-select";

function PostModal({ data, opens, onClose, isTrue, id, onUpdate }) {
  const baseURL = process.env.REACT_APP_BASE_URL;

  PostModal.propTypes = {
    data: PropTypes.object, // You can specify the shape of this object more precisely if needed
    opens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isTrue: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  };

  const [caption, setCaption] = useState("");
  const [location, setLocation] = useState("");
  const [coverimage, setCoverImage] = useState("");
  const [usersList, setUsersList] = useState();
  const [userID, setUserId] = useState();
  //const [id, setId] = useState("");

  useEffect(() => {
    setCaption(data?.caption);
    setLocation(data?.location);
    setCoverImage(data?.file_Location);
  }, [data]);

  // Error message
  const [errors, setError] = useState({});
  const [errorMessage, setErrors] = useState(undefined);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [searchKey, setShopSearchKey] = useState("");
  const handleClose = () => {
    setError({});
    setErrors();
    onClose();
  };

  const [groupAdmin, setGroupAdmin] = useState([]);
  const getUsers = async () => {
    try {
      const response = await getAllUsers(1, 1000, searchKey);
      if (response?.data && response?.data?.length > 0) {
        let _tempData = response?.data ?? [];
        console.log(_tempData);
        let _sortedData = _tempData.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
        setUsersList(_sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [searchKey]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose]);

  const notify = () => toast(isTrue ? "Post Updated Successfully!" : "Post Created Successfully!");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!caption) {
      errors.caption = "Caption Is Required";
    }
    if (!isTrue && !userID) {
      errors.userID = "User Is Required";
    }
    if (!isTrue && !coverimage) {
      errors.coverimage = "File data Is Required";
    }

    setLoadingCategory(true);

    setErrors(undefined);
    if (Object.keys(errors).length === 0) {
      try {
        if (isTrue) {
          const formData = {
            caption: caption,
            location: location,
          };
          const res = await EditPosts(data?.id, formData);
          notify();
          handleClose();
        } else {
          const formData = new FormData();
          formData.append("Caption", caption);
          formData.append("Location", location);
          formData.append("USERID", userID);
          formData.append("fileData", coverimage);
          const res = await createPosts(formData);
          notify();
          handleClose();
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          setErrors(err.response.data.message);
        } else {
          setErrors("Unknown error occurred");
        }
      } finally {
        setLoadingCategory(false);
        onUpdate();
        // Set loading to false, not true
      }
    }
    setError(errors);
    setLoadingCategory(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="addCategory">
      <Modal show={opens} size="lg" backdrop="static">
        <Modal.Header closeButton className="add_categorys" onClick={handleClose}>
          <Modal.Title>{isTrue ? <p> Edit Post</p> : <p> Add Post</p>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" mb-3 updatedoctor">
            <label className="pt-3">Caption</label>
            <input
              type="text"
              placeholder="Enter Caption"
              className="form-control"
              name="caption"
              onChange={(e) => setCaption(e.target.value)}
              defaultValue={data?.caption}
            />
            <div>
              {caption ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.caption && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.caption}
                    </span>
                  )}
                </div>
              )}
            </div>
            <label className="pt-3">Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="form-control"
              name="location"
              onChange={(e) => setLocation(e.target.value)}
              defaultValue={data?.location}
            />
            {!isTrue ? (
              <>
                <label className="pt-3">Cover Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={(e) => setCoverImage(e.target.files[0])}
                />
                <div>
                  {coverimage ? (
                    <div></div>
                  ) : (
                    <div className="error">
                      {errors.coverimage && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.coverimage}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                {/* <img
                  src={`${baseURL}${data?.file_Location}`}
                  alt="image"
                  width="250px"
                  height="150px"
                  style={{ marginTop: "10px" }}
                /> */}
              </>
            ) : null}
            {!isTrue ? (
              <>
                <label className="pt-3">User</label>
                {/* <select
                  className="form-control"
                  onChange={(e) => setUserId(e.target.value)}
                  name="user"
                >
                  <option> --Select User-- </option>
                  {usersList?.map((itm) => {
                    return (
                      <option key={itm?.id} value={itm?.id}>
                        {itm?.name}
                      </option>
                    );
                  })}
                </select> */}
                <Select
                  closeMenuOnSelect={true}
                  options={usersList}
                  name="user"
                  className="form-control"
                  isSearchable={true}
                  onInputChange={(shopSearchKey) => setShopSearchKey(shopSearchKey)}
                  onChange={(selectedOption) => {
                    setUserId(selectedOption.value);
                  }}
                />
                <div>
                  {userID ? (
                    <div></div>
                  ) : (
                    <div className="error">
                      {errors.userID && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.userID}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : null}

            <div className="pt-3"></div>
            {errorMessage ? (
              <div>
                <Badge pill bg="danger">
                  {errorMessage.toString()}
                </Badge>
              </div>
            ) : (
              <></>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={loadingCategory}>
            {loadingCategory ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span>{isTrue ? <spna>Update </spna> : <span>Add</span>}</span>
            )}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PostModal;
