import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsers } from "Service/UserService";
import Select from "react-dropdown-select";
import userimage from "../../assets/images/user.svg";
import closeImage from "../../assets/images/close_1.png";
import BaseURL from "Baseurl";
import { Link } from "react-router-dom";

function MakeMember({ opens, onClose, id, onUpdate, onUpdates }) {
  const baseURL = process.env.REACT_APP_BASE_URL;

  MakeMember.propTypes = {
    opens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onUpdates: PropTypes.func.isRequired,
    isTrue: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectValue, setSelectValue] = useState([]);

  const handleClose = () => {
    setSelectValue([]);
    onClose();
  };

  console.log("selectedOptions", selectedOptions);

  const [loadingCategory, setLoadingCategory] = useState(false);

  const [userList, setUsersList] = useState([]);

  const [options, setOptions] = useState([]);

  const [searchList, setSearchList] = useState("");

  const getUsers = async () => {
    let currentPage = 1;
    let pageLimit = 15;
    let userType = "normal";
    let search = searchList;
    try {
      const response = await getAllUsers(currentPage, pageLimit, search, userType);
      if (response?.data && response.data.length > 0) {
        setUsersList(response.data);
      } else {
        setUsersList([]); // No results found
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [searchList]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSelecet = (user) => {
    const userIndex = selectValue.findIndex((selectValue) => selectValue.id === user.id);
    if (userIndex === -1) {
      setSelectValue([...selectValue, user]); // Add user to selectValue
    } else {
      const updatedUsers = [...selectValue];
      updatedUsers.splice(userIndex, 1);
      setSelectValue(updatedUsers); // Remove user from selectValue
    }
    console.log("selectValue", selectValue);
  };

  const handleMakeMember = async () => {
    try {
      let response = await BaseURL.post(`/admin/Group/addMember`, {
        group: id.toString(),
        users: selectValue.map((selectValue) => selectValue.id),
      });
      if (response) {
        onUpdate();
        onUpdates();
        toast.success(response.data.message);
        handleClose();
        setSelectValue([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="addCategory">
      <Modal show={opens} size="lg" backdrop="static">
        <Modal.Header closeButton className="add_categorys" onClick={handleClose}>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" mb-3 updatedoctor">
            <label>User List</label>
            {/* <Select multi options={options} isSearchable onChange={handleSelectChange} /> */}
            <input
              type="text"
              className="form-control"
              placeholder="Search User"
              onChange={(e) => setSearchList(e.target.value)}
            />
            <div className="select_user_main">
              {selectValue.length > 0 ? <span>Selected User</span> : null}
              <div className="select_user">
                {selectValue.map((selectValue) => {
                  return (
                    <p key={selectValue.id} onClick={(e) => handleSelecet(selectValue)}>
                      {selectValue.profile_pic ? (
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${selectValue.profile_pic}`}
                          alt={selectValue.name}
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                      ) : (
                        <img
                          src={userimage}
                          alt={selectValue.name}
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                      )}
                      {selectValue.name}
                      <img src={closeImage} alt="image" className="close_image" />
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="chops">
              {userList.length > 0 ? (
                userList.map((user) => {
                  return (
                    <p
                      key={user.id}
                      onClick={(e) => handleSelecet(user)}
                      className={
                        selectValue.some((selectValue) => selectValue.id === user.id)
                          ? "selected"
                          : ""
                      }
                    >
                      {user.profile_pic ? (
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${user.profile_pic}`}
                          alt={user.name}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                      ) : (
                        <img
                          src={userimage}
                          alt={user.name}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                      )}
                      {/* {selectValue.some((selectValue) => selectValue.id === user.id) ? (
                        <Link to={`/users/${user.id}`}>{user.name}</Link>
                      ) : (
                       
                      )} */}
                      {user.name}
                    </p>
                  );
                })
              ) : (
                <span>User Not Found</span>
              )}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleMakeMember} disabled={loadingCategory}>
            {loadingCategory ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span>Add</span>
            )}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MakeMember;
