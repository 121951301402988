import BaseURL from "../Baseurl";

export const getAllEvents = async ({ page, limit }) => {
  try {
    const { data } = await BaseURL.get(`/admin/event/getAll?page=${page}&limit=${limit}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getEventMembers = async ({ id, page = 1, limit = 100 }) => {
  try {
    const { data } = await BaseURL.get(`/event/Members/${id}?page=${page}&limit=${limit}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const createEvent = async (forData) => {
  try {
    const { data } = await BaseURL.postForm("/admin/event/create", forData);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const EditEvent = async (id, forData) => {
  try {
    const { data } = await BaseURL.putForm(`/admin/event/update/${id}`, forData);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const DeleteEvent = async (id) => {
  try {
    const { data } = await BaseURL.delete(`/admin/event/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getEvent = async (id) => {
  try {
    const { data } = await BaseURL.get(`/Event/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getEventComments = async (id) => {
  try {
    const { data } = await BaseURL.get(`/EventComment?event_id=${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
