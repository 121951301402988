/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "layouts/users/Header";

// Data

// Images
import CircularProgress from "@mui/material/CircularProgress";
import { getUser, getUserArticles, getUserPosts } from "Service/UserService";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Card } from "@mui/material";
import { getUserMetaData } from "Service/UserService";

function UserInfo() {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState();
  const [postDetails, setPostDetails] = useState();
  const [articlesDetails, setArticlesDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState();
  const navigate = useNavigate();
  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await getUser(id);
      if (response?.data) {
        setUserDetails(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getUserPostDetails = async () => {
    try {
      setLoading(true);
      const response = await getUserPosts(1, 10, id);
      if (response) {
        setPostDetails(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleUserUpdate = async () => {
  //   await BaseURL.post("admin/User/updateProfile")
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getUserArticlesDetails = async () => {
    try {
      setLoading(true);
      const response = await getUserArticles(1, 10, id);
      if (response?.data) {
        setArticlesDetails(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const gteMetaData = async () => {
    try {
      const response = await getUserMetaData(id);
      setMetaData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserDetails();
    getUserPostDetails();
    getUserArticlesDetails();
    gteMetaData();
  }, []);
  const DisplayCard = ({ meta }) => {
    return (
      <Card
        sx={{
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          marginTop: "1rem",
          marginBottom: "10px",
        }}
      >
        <div className="col-md-12 ">
          <MDBox p={2}>
            {Object.keys(meta)
              .filter(
                (key) =>
                  key !== "agent" &&
                  key !== "fcm_token" &&
                  key !== "auth_token" &&
                  key !== "user_id" &&
                  key !== "browser_name" &&
                  key !== "browser_version" &&
                  key !== "device_product" &&
                  key !== "device_manufacturer"
              )
              .map((key) => (
                <div key={key} className="d-flex justify-content-between mb-2">
                  <MDTypography variant="button" color="text" fontWeight="light">
                    {key}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {meta[key]}
                  </MDTypography>
                </div>
              ))}
          </MDBox>
        </div>
      </Card>
    );
    DisplayCard.propTypes = {
      meta: PropTypes.string.isRequired, // Add this line for 'handleUpdate' prop
    };
  };
  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        {loading ? (
          <CircularProgress
            size={60}
            style={{ margin: "auto", display: "block", color: "#1A73E8" }}
          />
        ) : (
          <Header userDetails={userDetails} reload={getUserDetails}>
            <MDBox mt={5} mb={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={6}>
                  <ProfileInfoCard
                    title="profile information"
                    description={userDetails?.about}
                    info={{
                      fullName: userDetails?.name,
                      //   mobile: "(44) 123 1234 123",
                      email: userDetails?.email,
                      country: userDetails?.country,
                      User_role: userDetails?.user_role,
                      position: userDetails?.position,
                      organaization: userDetails?.organaization,
                      connections: userDetails?.no_of_connections,
                      Language: userDetails?.preferred_lang,
                      posts: userDetails?.no_of_posts,
                      articles: userDetails?.no_of_articles,
                      about: userDetails?.about,
                      National_Id: userDetails?.nationaL_ID,
                      id: id,
                    }}
                    action={{ tooltip: "Edit Profile" }}
                    shadow={true}
                    reload={getUserDetails}
                    profile_pic={userDetails?.profile_pic}
                    is_verified={userDetails?.isVerified}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <Card sx={{ height: "600px", boxShadow: false && "none", overflow: "auto" }}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt={2}
                      px={2}
                    >
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        User meta details
                      </MDTypography>
                    </MDBox>
                    {metaData &&
                      metaData.map((items, key) => <DisplayCard meta={items} key={key} />)}
                  </Card>
                </Grid>
                {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
              </Grid>
            </MDBox>
            <MDBox pt={2} px={2} lineHeight={1.25}>
              <MDTypography variant="h6" fontWeight="medium">
                Posts
              </MDTypography>
              <MDTypography variant="button" color="text">
                Latest 4 Posts
              </MDTypography>
            </MDBox>
            <MDBox p={2} style={{ boxShadow: "0 8px 24px hsla(210,8%,62%,.2)" }}>
              {postDetails && postDetails?.length > 4 && (
                <Button
                  variant="text"
                  onClick={() => navigate(`/posts?userId=${id}`)}
                  style={{ float: "right" }}
                >
                  See More
                </Button>
              )}
              <Grid container spacing={6}>
                {postDetails && postDetails?.length > 0 ? (
                  postDetails?.slice(0, 4).map((post, key) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={3}
                      key={key}
                      onClick={() => navigate(`/posts/${post?.id}`)}
                    >
                      <DefaultProjectCard
                        image={`${process.env.REACT_APP_BASE_URL}${post?.file_Location}`}
                        description={post?.caption}
                        info={{
                          likes: post?.post_likes,
                          comments: post?.post_comments,
                        }}
                        action={{
                          type: "internal",
                          route: `${process.env.REACT_APP_BASE_URL}${post?.file_Location}`,
                          color: "info",
                          label: "view post",
                        }}
                        label={post?.location}
                        date={post?.created}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} md={6} xl={3}>
                    <MDBox mb={1}>
                      <MDTypography variant="button" color="text">
                        No Posts
                      </MDTypography>
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </MDBox>
            <MDBox pt={2} px={2} lineHeight={1.25}>
              <MDTypography variant="h6" fontWeight="medium">
                Articles
              </MDTypography>
            </MDBox>
            <MDBox p={2} style={{ boxShadow: "0 8px 24px hsla(210,8%,62%,.2)" }}>
              <Grid container spacing={6}>
                {articlesDetails && articlesDetails?.length > 0 ? (
                  articlesDetails?.slice(0, 4).map((articles, key) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={3}
                      key={key}
                      onClick={() => navigate(`/article/${articles?.id}`)}
                    >
                      <DefaultProjectCard
                        image={`${process.env.REACT_APP_BASE_URL}${articles?.coverImage}`}
                        description={articles?.content}
                        info={{
                          comments: articles?.noOfComments,
                          likes: articles?.noOfLikes,
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} md={6} xl={3}>
                    <MDBox mb={1}>
                      <MDTypography variant="button" color="text">
                        No Articles
                      </MDTypography>
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Header>
        )}
        <Footer />
      </DashboardLayout>
    </Fragment>
  );
}

function redirectToAnotherURL() {
  window.location.href = `${window.location.toString()}/connections`;
}

export default UserInfo;
export { redirectToAnotherURL };
