import BaseURL from "../Baseurl";

export const getAllPosts = async (page, limit, userId) => {
  try {
    const { data } = await BaseURL.get(`/admin/Post?page=${page}&limit=${limit}&userId=${userId}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const createPosts = async (forData) => {
  try {
    const { data } = await BaseURL.postForm("/admin/Post", forData);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const EditPosts = async (id, forData) => {
  try {
    const { data } = await BaseURL.put(`/admin/Post/${id}`, forData);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getPost = async (id) => {
  try {
    const { data } = await BaseURL.get(`/Post/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getPostComments = async (id) => {
  try {
    const { data } = await BaseURL.get(`/admin/PostComment?post_id=${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
