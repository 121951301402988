/**
* Material Dashboard 2 React - v2.2.0

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventInfoCard from "examples/Cards/InfoCards/EventInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import EventCard from "examples/Cards/ProjectCards/EventCard";

// Overview page components
import Header from "layouts/events/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getUser } from "Service/UserService";
import { Fragment, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import BaseURL from "Baseurl";
import {
  Button,
  Card,
  Container,
  Icon,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import backgroundImage from "assets/images/bg-profile.jpeg";
import MDAvatar from "components/MDAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import { getEvent, DeleteEvent, getEventMembers, getEventComments } from "Service/EventServices";
import "./style.css";
import { OverlayTrigger } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import moment from "moment";
import EventModal from "./eventModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import user from "../../assets/images/output.png";

function EventDetails() {
  const navigate = useNavigate();

  const [editModal, setEditModal] = useState(false);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [comments, setComments] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [showAllComments, setShowAllComments] = useState(false);
  const displayedComments = showAllComments ? comments : comments.slice(0, 5);
  const getEventDetails = async () => {
    try {
      setLoading(true);
      const response = await getEvent(id);
      if (response?.data) {
        setEventDetails(response?.data);
      }
    } catch (error) {
      // console.log(error);
      toast.error("Event not found. Redirecting back to event page.");

      setTimeout(() => {
        navigate("/events");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };
  const getComments = async () => {
    try {
      setLoading(true);
      const response = await getEventComments(id);
      if (response) {
        setComments(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getMembers = async () => {
    console.log(id);
    try {
      setLoading(true);
      const response = await getEventMembers({ id: id });
      if (response?.data?.length) {
        setEventMembers(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const seeAllComments = () => {
    setShowAllComments(!showAllComments);
  };
  const handleUpdate = (data) => {
    setEditModal(true);
  };
  const handleDelete = (item) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Event?",
        text: "Are you sure you want to delete this Event?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            DeleteEvent(id).then((res) => {
              swalWithBootstrapButtons
                .fire("Deleted!", "Event has been deleted successfully.", "success")
                .then(() => {
                  navigate("/events");
                });
            });
          } catch (error) {
            console.error(error);
          }
        }
      });
  };
  const handleDeleteComment = (comment_id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Event Comment?",
        text: "Are you sure you want to delete this Comment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`Admin/Event/Comment/${comment_id}`, {
              id: comment_id,
            })
              .then((res) => {
                getComments();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Comment has been deleted successfully.",
                  "success"
                );
              })
              .catch((error) => {
                if (error.response) {
                  console.log("Error response data:", error.response.data.title);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    `${error.response.data.title}`,
                    "error"
                  );
                } else {
                  console.log("Network error:", error.message);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    "Post is not deleted. Network error occurred.",
                    "error"
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  };
  useEffect(() => {
    getEventDetails();
    getComments();
    getMembers();
  }, []);
  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        {loading ? (
          <CircularProgress
            size={60}
            style={{ margin: "auto", display: "block", color: "#1A73E8" }}
          />
        ) : (
          <MDBox>
            <a
              href={`${process.env.REACT_APP_BASE_URL}${eventDetails?.fileLocation}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDBox mt={5} mb={3}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  position="relative"
                  minHeight="18.75rem"
                  borderRadius="xl"
                  sx={{
                    backgroundImage: ({
                      functions: { rgba, linearGradient },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.info.main, 0),
                        rgba(gradients.info.state, 0)
                      )}, url(${process.env.REACT_APP_BASE_URL}${encodeURI(
                        eventDetails?.fileLocation
                      )})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                  }}
                />
              </MDBox>
            </a>
            <Card
              sx={{
                position: "relative",
                mt: -8,
                mx: 3,
                py: 2,
                px: 2,
              }}
            >
              <Grid container spacing={3} alignItems="center" style={{ cursor: "default" }}>
                <Grid item>
                  <Link to={`/users/${eventDetails?.userDetails?.id}`}>
                    {eventDetails?.userDetails?.profile_pic ? (
                      <MDAvatar
                        src={`${process.env.REACT_APP_BASE_URL}${eventDetails?.userDetails?.profile_pic}`}
                        alt="profile-image"
                        size="xl"
                        shadow="sm"
                      />
                    ) : (
                      <MDAvatar src={user} alt="profile-image" size="xl" shadow="sm" />
                    )}
                  </Link>
                </Grid>
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {eventDetails?.userDetails?.name}
                    </MDTypography>
                    <MDTypography
                      component={Link}
                      // to={action.route}
                      variant="body2"
                      color="secondary"
                      px={1}
                    >
                      <Tooltip
                        // title={action.tooltip}
                        placement="top"
                        onClick={handleUpdate}
                      >
                        <Icon>edit</Icon>
                      </Tooltip>
                      <Tooltip
                        // title={action.tooltip}
                        placement="top"
                        onClick={handleDelete}
                      >
                        <Icon>delete</Icon>
                      </Tooltip>
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {eventDetails?.caption}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            <Header>
              <Container className="container" style={{ marginLeft: 0, marginRight: 0 }}>
                <MDBox mt={5} mb={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} xl={6}>
                      <EventInfoCard
                        title="Event information"
                        description={eventDetails?.about}
                        info={{
                          eventName: eventDetails?.event_name,
                          location: eventDetails?.event_location,
                          startDate: moment(eventDetails?.start_date).format("MMMM Do YYYY"),
                          endDate: moment(eventDetails?.end_date).format("MMMM Do YYYY"),
                          volunteerCount: eventDetails?.volunteer_count,
                          volunteerLimit: eventDetails?.volunteer_limit,
                          startTime: eventDetails?.start_time,
                          endTime: eventDetails?.end_time,
                          geolocation:
                            eventDetails?.location_lat.substring(0, 10) +
                            "," +
                            eventDetails?.location_long.substring(0, 10),
                        }}
                        shadow={true}
                        action={{ tooltip: "Edit Profile" }}
                        eventMembers={eventMembers}
                      />
                      <Divider orientation="vertical" sx={{ mx: 0 }} />
                    </Grid>
                    {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
                  </Grid>
                </MDBox>
                <MDBox pt={2} px={2} lineHeight={1.25}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Gallery
                  </MDTypography>
                </MDBox>
                <MDBox p={2} style={{ boxShadow: "0 8px 24px hsla(210,8%,62%,.2)" }}>
                  <Grid container spacing={6}>
                    {eventDetails && eventDetails?.files?.length > 0 ? (
                      eventDetails?.files.map((post, key) => (
                        <Grid item xs={12} md={6} xl={3} key={key}>
                          <EventCard image={`${process.env.REACT_APP_BASE_URL}${post}`} />
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12} md={6} xl={3}>
                        <MDBox mb={1}>
                          <MDTypography variant="button" color="text">
                            No Posts
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
              </Container>
              <Card
                sx={{ mt: 3, mx: 3, py: 2, px: 2 }}
                style={{ maxHeight: "340px", overflowY: " overlay", cursor: "default" }}
              >
                <MDBox pt={2} px={2} lineHeight={1.25}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Comments
                  </MDTypography>
                </MDBox>
                <Container className="container" style={{ marginLeft: 0, marginRight: 0 }}>
                  {/* <TextField
                  label="Write a Comment"
                  multiline
                  //   rows={4}
                  variant="outlined"
                  value={newComment}
                  onChange={handleCommentChange}
                  style={{ width: "100%" }}
                />
                <Button
                  sx={{
                    position: "relative",
                    mt: -5,
                    //   mx: 3,
                    //   py: 2,
                    //   px: 2,
                    float: "right",
                  }}
                  onClick={handleCommentSubmit}
                >
                  Post
                </Button> */}
                  <List>
                    {displayedComments && displayedComments.length > 0 ? (
                      displayedComments.map((comment, index) => (
                        <ListItem key={index}>
                          <Link to={`/users/${comment?.userDetails?.id}`}>
                            {comment?.userDetails?.profile_pic ? (
                              <MDAvatar
                                src={`${process.env.REACT_APP_BASE_URL}${comment?.userDetails?.profile_pic}`}
                                alt="profile-image"
                                size="m"
                                shadow="sm"
                                sx={{ mt: 2 }}
                              />
                            ) : (
                              <MDAvatar
                                src={user}
                                alt="profile-image"
                                size="m"
                                shadow="sm"
                                sx={{ mt: 2 }}
                              />
                            )}
                          </Link>
                          <MDBox
                            height="100%"
                            mt={1}
                            mx={1}
                            lineHeight={1}
                            sx={{ display: "grid" }}
                          >
                            <MDTypography variant="button" fontWeight="medium" fontSize="12px">
                              {comment?.userDetails?.name}
                              <MDTypography
                                variant="button"
                                color="text"
                                fontWeight="regular"
                                fontSize="12px"
                                sx={{ marginLeft: "10px" }}
                              >
                                <small>
                                  on {""} {moment(comment?.created).format("MMMM DD YYYY")}
                                </small>
                              </MDTypography>
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              fontSize="12px"
                            >
                              {comment?.comments}
                            </MDTypography>
                            <div>
                              <FavoriteIcon fontSize="small" sx={{ width: "0.8em" }} />
                              <span style={{ fontSize: "14px" }}>{comment?.like_count}</span>
                              <DeleteIcon
                                onClick={() => handleDeleteComment(comment?.id)}
                                sx={{
                                  fontSize: 10,
                                  cursor: "pointer",
                                  width: "0.8em",
                                  marginLeft: "1em",
                                }}
                              />
                            </div>
                          </MDBox>
                        </ListItem>
                      ))
                    ) : (
                      <MDTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                        fontSize="12px"
                      >
                        No comments
                      </MDTypography>
                    )}
                  </List>
                  {comments.length > 5 && (
                    <Button variant="text" onClick={seeAllComments}>
                      {showAllComments ? "See Less" : "See More"}
                    </Button>
                  )}
                </Container>
              </Card>
            </Header>
          </MDBox>
        )}
        <Footer />
      </DashboardLayout>
      {editModal && (
        <EventModal
          opens={editModal}
          data={eventDetails}
          isTrue={editModal}
          // id={groupData?.id}
          onUpdate={() => getEventDetails()}
          onClose={() => setEditModal(false)}
        />
      )}
    </Fragment>
  );
}

export default EventDetails;
