// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//react
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "react-data-table-component-extensions/dist/index.css";

// Data
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types"; // Import PropTypes
import { Fragment, useEffect, useState } from "react";
import { getAllAdmin } from "Service/AdminService";
import team2 from "assets/images/team-2.jpg";
import { useNavigate } from "react-router-dom";
import { Image } from "@mui/icons-material";
import moment from "moment";
import BaseURL from "Baseurl";
// import GruopModel from "./EditGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "../tables/table.css";
import { useLocation } from "react-router-dom";
import user from "../../assets/images/output.png";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Swal from "sweetalert2";
import AdminEditModel from "./AdminEditModel";
import { CircularProgress } from "@mui/material";

function Admin() {
  const [totalRows, setTotalRows] = useState(0);

  let userType = "admin";

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1
  );
  const [pageLimit, setPerPage] = useState(
    parseInt(searchParams.get("limit")) ? parseInt(searchParams.get("limit")) : 10
  );

  const baseURL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Make sure to adjust the type as needed
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  Job.propTypes = {
    title: PropTypes.string.isRequired, // Make sure to adjust the type as needed
  };
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    setLoading(true);

    try {
      const response = await getAllAdmin({
        limit: pageLimit,
        page: currentPage,
        userType,
      });

      if (response && response.data && response.data.length > 0) {
        console.log("res", response.data);
        setTotalRows(response.total_count);
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, pageLimit]);

  const columns = [
    {
      name: "Profile Pic",
      selector: (row) => [row?.profile_pic],
      cell: (row) => (
        <div>
          {row?.profile_pic ? (
            <img
              src={`${process.env.REACT_APP_BASE_URL}${row?.profile_pic}`}
              width="80px"
              height="80px"
              style={{ objectFit: "cover" }}
            />
          ) : (
            <img src={user} width="80px" height="80px" />
          )}
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => [row?.name],
      cell: (row) => <div>{row?.name}</div>,
    },
    {
      name: "Email",
      selector: (row) => [row?.email],
      cell: (row) => <div>{row?.email}</div>,
    },
    {
      name: "National Id",
      selector: (row) => [row?.nationaL_ID],
      cell: (row) => <div>{row?.nationaL_ID}</div>,
    },
    {
      name: "Country",
      selector: (row) => [row.country],
      cell: (row) => <div>{row?.country}</div>,
    },
    {
      name: "Position",
      selector: (row) => [row.position],
      cell: (row) => <div>{row?.position}</div>,
    },
    {
      name: "User Role",
      selector: (row) => [row.user_role],
      cell: (row) => <div>{row?.user_role}</div>,
    },
    {
      name: "Created",
      selector: (row) => [row.created],
      cell: (row) => <div>{moment(row.created).format("LL")}</div>,
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const handleRowClick = (rowData) => {
    navigate(`/admin/${rowData?.id}`);
    // Now you can access the details of the clicked row in rowData
  };

  const [opencategory, setopenCategory] = useState(false);
  const [istrue, setIsTrue] = useState("");
  const [groupData, setGroupData] = useState(null);

  const handleClickCategory = (item) => {
    console.log("data", item);
    setopenCategory(true);
    setGroupData(item);
    setIsTrue(true);
  };

  const [opengroup, setOpenGroup] = useState(false);

  const handleCreate = () => {
    setOpenGroup(true);
  };
  if (loading) {
    return (
      <CircularProgress
        size={60} // Adjust the size here
        style={{
          margin: "auto",
          display: "block",
          color: "#1A73E8",
          position: "relative",
          top: "20rem",
        }}
      />
    );
  } else {
    return (
      <Fragment>
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <div
                      className="group"
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <MDTypography variant="h6" color="white">
                        Admin Table
                      </MDTypography>

                      <MDTypography
                        variant="h6"
                        color="white"
                        onClick={handleCreate}
                        className="add"
                        style={{
                          border: "1px solid white",
                          padding: "4px 10px",
                          ccursor: "pointer",
                        }}
                      >
                        Add Admin
                      </MDTypography>
                    </div>
                  </MDBox>
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      data={data}
                      columns={columns}
                      paginationServer={true}
                      pagination={true}
                      paginationDefaultPage={currentPage}
                      paginationPerPage={pageLimit}
                      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                      paginationTotalRows={totalRows}
                      onRowClicked={handleRowClick}
                      style={{ crusor: "ponter" }}
                      onChangeRowsPerPage={(p) => {
                        setPerPage(p);
                        setCurrentPage(1);
                      }}
                      onChangePage={(p) => setCurrentPage(p)}
                    />
                  </DataTableExtensions>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
        <AdminEditModel
          opens={opengroup}
          onUpdate={() => getUsers()}
          onClose={() => setOpenGroup(false)}
        />
      </Fragment>
    );
  }
}

export default Admin;
