import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Badge, Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import ArticleService from "Service/Article/ArticleService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsers } from "Service/UserService";
import Select from "react-select";

function ModelArticle({ data, opens, onClose, isTrue, id, onUpdate }) {
  const baseURL = process.env.REACT_APP_BASE_URL;

  ModelArticle.propTypes = {
    data: PropTypes.object, // You can specify the shape of this object more precisely if needed
    opens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isTrue: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  };

  console.log("group", id);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [location, setLocation] = useState("");
  const [lat, setLat] = useState(null);
  const [lang, setLang] = useState(null);
  const [coverimage, setCoverImage] = useState("");
  const [file, setFile] = useState("");
  const [user, setUser] = useState("");

  //const [id, setId] = useState("");

  useEffect(() => {
    setTitle(data?.title);
    setContent(data?.content);
    setLocation(data?.location);
    setLat(data?.location_lat);
    setLang(data?.location_long);
    setCoverImage(data?.coverImage);
    setFile(data?.file);
    setUser(data?.userDetails?.id);
  }, [data]);

  // Error message
  const [errors, setError] = useState({});
  const [errorMessage, setErrors] = useState(undefined);
  const [loadingCategory, setLoadingCategory] = useState(false);

  const handleClose = () => {
    setError({});
    onClose();
  };

  const [groupAdmin, setGroupAdmin] = useState([]);
  const getOwner = async () => {
    await getAllUsers(1, 1000)
      .then((res) => {
        setGroupAdmin(res.data);
        console.log("groupadmin", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOwner();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose]);

  const notify = () =>
    toast(isTrue ? "Article Updated Successfully!" : "Article Created Successfully!");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const errors = {};

    if (!title) {
      errors.title = "Title Is Required";
    }

    if (!coverimage) {
      errors.coverimage = "Cover Image  Is Required";
    }

    if (!user) {
      errors.user = "User Is Required";
    }

    setLoadingCategory(true);

    setErrors(undefined);
    if (Object.keys(errors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("Content", content);
        formData.append("location", location);
        if (isTrue) {
          formData.append("coverImageFile", coverimage);
        }
        formData.append("coverImage", coverimage);
        formData.append("location_lat", lat);
        formData.append("location_lng", lang);
        formData.append("fileData", file);
        formData.append("User_id", user);
        if (isTrue) {
          await ArticleService.update(formData, id)
            .then((res) => {
              onUpdate(res.data);
              notify();
              handleClose();
            })
            .catch((err) => {
              console.log("error", err);
              toast.error(err.response.data.title);
              if (err.response && err.response.data && err.response.data.title) {
                setErrors(err.response.data.title);
              } else {
                setErrors("Unknown error occurred");
              }
            });
        } else {
          await ArticleService.create(formData)
            .then((res) => {
              onUpdate(res.data);
              notify();
              handleClose();
            })
            .catch((err) => {
              console.log("error", err);
              toast.error(err.response.data.title);
              if (
                err.response &&
                err.response.data &&
                err.response.data.title &&
                err.response.data.errors
              ) {
                setErrors(err.response.data.title);
                setErrors(err.response.data.errors.location_lat);
                setErrors(err.response.data.errors.location_lng);
              } else {
                setErrors("Unknown error occurred");
              }
            });
        }
      } finally {
        setLoadingCategory(false); // Set loading to false, not true
      }
    }
    setError(errors);
    setLoadingCategory(false);
  };

  const [shopSearchResult, setShopSearchResult] = useState([]);
  const [shopSearchKey, setShopSearchKey] = useState("");
  const [loadingshopSearch, setLoadingShopSearch] = useState(false);

  const shopSearchData = async () => {
    setLoadingShopSearch(true);
    await getAllUsers(1, 1000)
      .then((res) => {
        let _tempData = res.data ?? [];
        console.log(_tempData);
        let _sortedData = _tempData.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
        setShopSearchResult(_sortedData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingShopSearch(false);
      });
  };

  useEffect(() => {
    shopSearchData();
  }, [shopSearchKey]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="addCategory">
      <Modal show={opens} size="lg" backdrop="static">
        <Modal.Header closeButton className="add_categorys" onClick={handleClose}>
          <Modal.Title>{isTrue ? <p> Edit Article</p> : <p> Add Article</p>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" mb-3 updatedoctor">
            <label className="pt-3">Title</label>
            <input
              type="text"
              placeholder="Enter Tile"
              className="form-control"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              defaultValue={data?.title}
            />
            <div>
              {title ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.title && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.title}
                    </span>
                  )}
                </div>
              )}
            </div>
            {!isTrue ? (
              <>
                <label>User</label>
                <Select
                  closeMenuOnSelect={true}
                  options={shopSearchResult}
                  name="user"
                  className="form-control"
                  isSearchable={true}
                  isLoading={loadingshopSearch}
                  defaultValue={shopSearchResult.find(
                    (option) => option.value === data?.userDetails.id
                  )}
                  onInputChange={(shopSearchKey) => setShopSearchKey(shopSearchKey)}
                  onChange={(selectedOption) => {
                    setUser(selectedOption.value);
                    console.log(selectedOption.value);
                  }}
                />
                <div>
                  {user ? (
                    <div></div>
                  ) : (
                    <div className="error">
                      {errors.user && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.user}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : null}
            <label className="pt-3">Content</label>
            <input
              type="text"
              placeholder="Enter Content"
              className="form-control"
              name="content"
              onChange={(e) => setContent(e.target.value)}
              defaultValue={data?.content}
            />
            <label className="pt-3">Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="form-control"
              name="location"
              onChange={(e) => setLocation(e.target.value)}
              defaultValue={data?.location}
            />
            <div>
              {location ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.location && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.location}
                    </span>
                  )}
                </div>
              )}
            </div>
            <label className="pt-3">Loation latitude</label>
            <input
              type="number"
              placeholder="Enter  latitude"
              className="form-control"
              name="lat"
              onChange={(e) => setLat(e.target.value)}
              defaultValue={data?.location_lat}
            />
            <label className="pt-3">Loation longitude</label>
            <input
              type="number"
              placeholder="Enter longitude"
              className="form-control"
              name="lang"
              onChange={(e) => setLang(e.target.value)}
              defaultValue={data?.location_long}
            />
            <label className="pt-3">File</label>
            <input
              type="file"
              className="form-control"
              name="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label className="pt-3">Cover Image</label>
            <input
              type="file"
              className="form-control"
              name="coverimage"
              onChange={(e) => setCoverImage(e.target.files[0])}
            />
            <div>
              {coverimage ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.coverimage && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.coverimage}
                    </span>
                  )}
                </div>
              )}
            </div>
            {isTrue ? (
              <img
                src={`${baseURL}${data?.coverImage}`}
                alt="image"
                width="250px"
                height="150px"
                style={{ marginTop: "10px" }}
              />
            ) : null}

            <div className="pt-3"></div>
            {errorMessage ? (
              <div>
                <Badge pill bg="danger">
                  {errorMessage.toString()}
                </Badge>
              </div>
            ) : (
              <></>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={loadingCategory}>
            {loadingCategory ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span>{isTrue ? <spna>Update </spna> : <span>Add</span>}</span>
            )}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModelArticle;
