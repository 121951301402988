import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Badge, Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import GroupService from "Service/Group/GroupService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsers } from "Service/UserService";

function GruopModel({ data, opens, onClose, isTrue, id, onUpdate }) {
  const baseURL = process.env.REACT_APP_BASE_URL;

  GruopModel.propTypes = {
    data: PropTypes.object, // You can specify the shape of this object more precisely if needed
    opens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isTrue: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  };

  console.log("group", id);

  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [group_type, setGroupType] = useState("");
  const [coverimage, setCoverImage] = useState("");
  const [groupOwner, setGroupOwner] = useState("");
  //const [id, setId] = useState("");

  useEffect(() => {
    setName(data?.name);
    setAbout(data?.about);
    setGroupType(data?.group_type);
    setCoverImage(data?.cover_image);
  }, [data]);

  // Error message
  const [errors, setError] = useState({});
  const [errorMessage, setErrors] = useState(undefined);
  const [loadingCategory, setLoadingCategory] = useState(false);

  const handleClose = () => {
    setError({});
    onClose();
  };

  const [groupAdmin, setGroupAdmin] = useState([]);
  const getOwner = async () => {
    await getAllUsers(1, 1000)
      .then((res) => {
        setGroupAdmin(res.data);
        console.log("groupadmin", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOwner();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose]);

  const notify = () =>
    toast(isTrue ? "Group Updated Successfully!" : "Group Created Successfully!");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const errors = {};

    if (!name) {
      errors.name = "Name Is Required";
    }

    if (!group_type) {
      errors.group_type = "Group Type Is Required";
    }
    if (!isTrue) {
      if (!groupOwner) {
        errors.groupOwner = "Group Owner Is Required";
      }
    }

    setLoadingCategory(true);

    setErrors(undefined);
    if (Object.keys(errors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("about", about);
        formData.append("group_type", group_type);
        formData.append("coverImage", coverimage);
        formData.append("Group_owner", groupOwner);
        if (isTrue) {
          await GroupService.update(formData, id)
            .then((res) => {
              onUpdate(res.data);
            })
            .catch((err) => {
              if (err.response && err.response.data && err.response.data.message) {
                setErrors(err.response.data.message);
              } else {
                setErrors("Unknown error occurred");
              }
            });
        } else {
          await GroupService.create(formData)
            .then((res) => {
              onUpdate(res.data);
            })
            .catch((err) => {
              if (err.response && err.response.data && err.response.data.message) {
                setErrors(err.response.data.message);
              } else {
                setErrors("Unknown error occurred");
              }
            });
        }
        notify();
        handleClose();
      } finally {
        setLoadingCategory(false); // Set loading to false, not true
      }
    }
    setError(errors);
    setLoadingCategory(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="addCategory">
      <Modal show={opens} size="lg" backdrop="static">
        <Modal.Header closeButton className="add_categorys" onClick={handleClose}>
          <Modal.Title>{isTrue ? <p> Edit Group</p> : <p> Add Group</p>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" mb-3 updatedoctor">
            <label className="pt-3">Name</label>
            <input
              type="text"
              placeholder="Enter Name"
              className="form-control"
              name="name"
              onChange={(e) => setName(e.target.value)}
              defaultValue={data?.name}
            />
            <div>
              {name ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.name && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.name}
                    </span>
                  )}
                </div>
              )}
            </div>
            <label className="pt-3">About</label>
            <input
              type="text"
              placeholder="Enter About"
              className="form-control"
              name="about"
              onChange={(e) => setAbout(e.target.value)}
              defaultValue={data?.about}
            />
            <label className="pt-3">Group Type</label>
            <select
              onChange={(e) => setGroupType(e.target.value)}
              name="group_type"
              defaultValue={data?.group_type}
              className="form-control"
            >
              <option>--Select Group Type --</option>
              <option value="Public">Public</option>
              <option value="Private">Private</option>
            </select>
            <div>
              {group_type ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.group_type && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.group_type}
                    </span>
                  )}
                </div>
              )}
            </div>

            {!isTrue ? (
              <>
                <label className="pt-3">Group Owner</label>
                <select
                  className="form-control"
                  onChange={(e) => setGroupOwner(e.target.value)}
                  name="groupOwner"
                >
                  <option> --Select Group Owner-- </option>
                  {groupAdmin.map((itm) => {
                    return (
                      <option key={itm?.id} value={itm?.id}>
                        {itm?.name}
                      </option>
                    );
                  })}
                </select>
                <div>
                  {groupOwner ? (
                    <div></div>
                  ) : (
                    <div className="error">
                      {errors.groupOwner && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.groupOwner}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : null}

            <label className="pt-3">Cover Image</label>
            <input
              type="file"
              className="form-control"
              name="image"
              onChange={(e) => setCoverImage(e.target.files[0])}
            />
            {isTrue ? (
              <img
                src={`${baseURL}${data?.cover_image}`}
                alt="image"
                width="250px"
                height="150px"
                style={{ marginTop: "10px" }}
              />
            ) : null}

            <div className="pt-3"></div>
            {errorMessage ? (
              <div>
                <Badge pill bg="danger">
                  {errorMessage.toString()}
                </Badge>
              </div>
            ) : (
              <></>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={loadingCategory}>
            {loadingCategory ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span>{isTrue ? <spna>Update </spna> : <span>Add</span>}</span>
            )}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default GruopModel;
