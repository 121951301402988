import BaseURL from "Baseurl";

const getAll = ({ page, limit }) => {
  return BaseURL.get(`admin/Article?page=${page}&limit=${limit}`);
};

const get = (id) => {
  return BaseURL.get(`appointment/admin/${id}`);
};

const create = (data) => {
  return BaseURL.postForm(`/admin/Article`, data);
};

const update = (data, id) => {
  return BaseURL.putForm(`/admin/Article/${id}`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`admin/Group/${_id}`);
};
export const getArticleDetailsData = async (id) => {
  const { data } = await BaseURL.get(`admin/article/${id}`);
  return data;
};

export const getArticleCommentData = async (id) => {
  const { data } = await BaseURL.get(`/ArticleComment?article_id=${id}`);
  return data;
};

const ArticleService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default ArticleService;
