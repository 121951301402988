import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Badge, Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { EditAdmin } from "Service/AdminService";
import { createAdmin } from "Service/AdminService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsers } from "Service/UserService";
import { Password } from "@mui/icons-material";
import noimage from "../../assets/images/noimagepng.png";

function AdminEditModel({ data, opens, onClose, isTrue, id, onUpdate }) {
  const baseURL = process.env.REACT_APP_BASE_URL;

  AdminEditModel.propTypes = {
    data: PropTypes.object, // You can specify the shape of this object more precisely if needed
    opens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isTrue: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  };

  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [email, setEmail] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [usertype, setUserType] = useState("admin");
  const [country, setCountry] = useState("");
  const [posistion, setPosistion] = useState("");
  const [password, setPassword] = useState("");
  const [coverimage, setCoverImage] = useState(null);

  const [organaization, setOrganaization] = useState("");
  const [organizationbranch, setOrganizationbranch] = useState("");
  const [organizationlocation, setorganizationlocation] = useState("");

  const [preferredlang, setpreferredlang] = useState("");

  //const [id, setId] = useState("");

  useEffect(() => {
    setName(data?.name);
    setAbout(data?.about);
    setEmail(data?.email);
    setNationalId(data?.nationaL_ID);
    setUserType(data?.user_role);
    setCountry(data?.country);
    setPosistion(data?.position);
    setPassword(data?.password);
    setOrganaization(data?.organaization);
    setOrganizationbranch(data?.organization_branch);
    setorganizationlocation(data?.organization_location);
    setpreferredlang(data?.preferred_lang);
  }, [data]);

  // Error message
  const [errors, setError] = useState({});
  const [errorMessage, setErrors] = useState(undefined);
  const [loadingCategory, setLoadingCategory] = useState(false);

  const [emailerror, setEmailError] = useState(undefined);

  console.log("emailerror", emailerror);

  const handleClose = () => {
    setError({});
    onClose();
  };

  const [groupAdmin, setGroupAdmin] = useState([]);

  const getOwner = async () => {
    await getAllUsers(1, 1000)
      .then((res) => {
        setGroupAdmin(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOwner();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose]);

  const notify = () =>
    toast(isTrue ? "Admin Updated Successfully!" : "Admin Created Successfully!");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const errors = {};

    if (!name) {
      errors.name = "Name Is Required";
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!isTrue) {
      if (!email && !emailRegex.test(email)) {
        errors.email = "Email Is Required";
      } else if (!emailRegex.test(email)) {
        errors.email = "Invalid Email Format";
        setEmailError("Invalid Email Format");
      }
    }
    if (!isTrue) {
      if (!password) {
        errors.password = "Password Is Required";
      }
    }

    setLoadingCategory(true);

    setErrors(undefined);
    if (Object.keys(errors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("about", about);
        formData.append("email", email);
        formData.append("nationaL_ID", nationalId);
        formData.append("country", country);
        formData.append("position", posistion);
        formData.append("user_role", "admin");
        formData.append("password", password);
        formData.append("fileData", coverimage);
        formData.append("preferred_lang", preferredlang);
        formData.append("organaization", organaization);
        formData.append("organization_branch", organizationbranch);
        formData.append("organization_location", organizationlocation);
        if (isTrue) {
          await EditAdmin(id, formData)
            .then((res) => {
              onUpdate(res.data);
              notify();
              handleClose();
            })
            .catch((err) => {
              toast.error(err.response.data.message);
              if (err.response && err.response.data && err.response.data.message) {
                setErrors(err.response.data.detail);
                setErrors(err.response.data.title);
              } else {
                setErrors("Unknown error occurred");
              }
            });
        } else {
          await createAdmin(formData)
            .then((res) => {
              onUpdate(res.data);
              notify();
              handleClose();
            })
            .catch((err) => {
              setEmailError(err.response.data.detail);
              toast.error(err.response.data.detail);
              console.log("error", err.response.data.detail);
              if (err.response && err.response.data && err.response.data.title) {
                setErrors(err.response.data.title);
                setErrors(err.response.data.detail);
              } else {
                setErrors("Unknown error occurred");
              }
            });
        }
      } finally {
        setLoadingCategory(false); // Set loading to false, not true
      }
    }
    setError(errors);
    setLoadingCategory(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="addCategory">
      <Modal show={opens} size="lg" backdrop="static">
        <Modal.Header closeButton className="add_categorys" onClick={handleClose}>
          <Modal.Title>{isTrue ? <p> Edit Admin</p> : <p> Add Admin</p>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" mb-3 updatedoctor">
            <label className="pt-3">Name</label>
            <input
              type="text"
              placeholder="Enter Name"
              className="form-control"
              name="name"
              onChange={(e) => setName(e.target.value)}
              defaultValue={data?.name}
            />
            <div>
              {name ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.name && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.name}
                    </span>
                  )}
                </div>
              )}
            </div>
            <label className="pt-3">Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="form-control"
              name="email"
              onChange={(e) => {
                setEmailError("");
                setEmail(e.target.value);
              }}
              defaultValue={data?.email}
            />
            <div>
              {email ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.email && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.email}
                    </span>
                  )}
                </div>
              )}
              <div className="error">
                {" "}
                <span className="error" style={{ color: "red" }}>
                  {emailerror}
                </span>
              </div>
            </div>
            {/* <label className="pt-3">User Role</label>
            <select
              onChange={(e) => setUserType(e.target.value)}
              className="form-control"
              defaultValue={data?.user_role}
            >
              <option value="admin">admin</option>
            </select>
            <div>
              {usertype ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.usertype && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.usertype}
                    </span>
                  )}
                </div>
              )}
            </div> */}
            <label className="pt-3">Password</label>
            <input
              type="password"
              placeholder="Enter password"
              className="form-control"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              defaultValue={data?.password}
            />
            <div>
              {password ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.password && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.password}
                    </span>
                  )}
                </div>
              )}
            </div>
            {isTrue ? (
              <>
                <h5 className="pt-3">More options</h5>
                <label className="pt-3">About</label>
                <input
                  type="text"
                  placeholder="Enter About"
                  className="form-control"
                  name="about"
                  onChange={(e) => setAbout(e.target.value)}
                  defaultValue={data?.about}
                />
                <label className="pt-3">National Id</label>
                <input
                  type="text"
                  placeholder="Enter National Id"
                  className="form-control"
                  name="nationalid"
                  onChange={(e) => setNationalId(e.target.value)}
                  defaultValue={data?.nationaL_ID}
                />
                <label className="pt-3">Country</label>
                <input
                  type="text"
                  placeholder="Enter Country"
                  className="form-control"
                  name="country"
                  onChange={(e) => setCountry(e.target.value)}
                  defaultValue={data?.country}
                />
                <label className="pt-3">Preferred Lang</label>
                <select
                  onChange={(e) => setpreferredlang(e.target.value)}
                  className="form-control"
                  defaultValue={data?.preferred_lang}
                >
                  <option>-- select Language --</option>
                  <option value="english">English</option>
                  <option value="arabic">Arabic</option>
                </select>
                <label className="pt-3">Position</label>
                <input
                  type="text"
                  placeholder="Enter posistion"
                  className="form-control"
                  name="posistion"
                  onChange={(e) => setPosistion(e.target.value)}
                  defaultValue={data?.position}
                />
                <label className="pt-3">Organaization</label>
                <input
                  type="text"
                  placeholder="Enter organaization"
                  className="form-control"
                  name="organaization"
                  onChange={(e) => setOrganaization(e.target.value)}
                  defaultValue={data?.organaization}
                />
                <label className="pt-3">Organization branch</label>
                <input
                  type="text"
                  placeholder="Enter organization branch"
                  className="form-control"
                  name="organization_branch"
                  onChange={(e) => setOrganizationbranch(e.target.value)}
                  defaultValue={data?.organization_branch}
                />
                <label className="pt-3">Organization Location</label>
                <input
                  type="text"
                  placeholder="Enter organization location"
                  className="form-control"
                  name="organization_location"
                  onChange={(e) => setorganizationlocation(e.target.value)}
                  defaultValue={data?.organization_location}
                />
                <label className="pt-3">Profile Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={(e) => setCoverImage(e.target.files[0])}
                />
                {data?.profile_pic ? (
                  <img
                    src={`${baseURL}${data?.profile_pic}`}
                    alt="image"
                    width="250px"
                    height="150px"
                    style={{ marginTop: "10px" }}
                  />
                ) : (
                  <img src={noimage} alt="image" width="100px" height="100px" />
                )}
              </>
            ) : null}
            <div className="pt-3"></div>
            {errorMessage ? (
              <div>
                <Badge pill bg="danger">
                  {errorMessage.toString()}
                </Badge>
              </div>
            ) : (
              <></>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={loadingCategory}>
            {loadingCategory ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span>{isTrue ? <span>Update </span> : <span>Add</span>}</span>
            )}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminEditModel;
