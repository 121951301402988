import BaseURL from "Baseurl";

export const getAllAdmin = async ({ page, limit, userType }) => {
  const hasToken = localStorage.getItem("token");
  if (!hasToken) return;
  try {
    const { data } = await BaseURL.get(
      `/admin/User?page=${page}&limit=${limit}&userType=${userType}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createAdmin = async (forData) => {
  try {
    const { data } = await BaseURL.post("/admin/User/AddUser", forData);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const EditAdmin = async (id, forData) => {
  try {
    const { data } = await BaseURL.putForm(`/admin/User/updateProfile/${id}`, forData);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAdmin = async (id) => {
  try {
    const { data } = await BaseURL.get(`/admin/User/user_details/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
