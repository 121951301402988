import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, CircularProgress, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";
import { getAllPosts } from "Service/PostServices";
import { Fragment, useEffect, useState, useRef } from "react";
import moment from "moment/moment";
import PostModal from "./postModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import BaseURL from "Baseurl";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import Footer from "examples/Footer";
import { useLocation } from "react-router-dom";
import noimage from "../../assets/images/noimagepng.png";
import { Link, useNavigate } from "react-router-dom";
import "./image-style.css";
import { redirectToUser } from "../users/index";

function Posts() {
  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Make sure to adjust the type as needed
    name: PropTypes.string.isRequired,
    // email: PropTypes.string.isRequired,
  };
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  Job.propTypes = {
    title: PropTypes.string.isRequired, // Make sure to adjust the type as needed
  };

  const [totalRows, setTotalRows] = useState(0);
  const [postList, setPostList] = useState([]);
  const [opneModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1
  );
  const [pageLimit, setPerPage] = useState(
    parseInt(searchParams.get("limit")) ? parseInt(searchParams.get("limit")) : 10
  );
  const userId = parseInt(searchParams.get("userId") ? searchParams.get("userId") : "");
  const getPosts = async () => {
    try {
      setLoading(true);
      const response = await getAllPosts(currentPage, pageLimit, userId ? userId : "");
      if (response?.data) {
        let posts = response?.data?.map((itm) => itm);
        setPostList(response?.data);
        setData(posts);
        setTotalRows(response?.total_count);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleCreate = () => {
    setOpenModal(true);
  };
  const handleEditPost = (data) => {
    if (data) {
      setEditModal(true);
      setEditData(data);
    }
  };

  const handleRowsPerPageChange = (rowsPerPage, currentPage) => {
    setPerPage(rowsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDelete = (post) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Post?",
        text: "Are you sure you want to delete this Post?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`admin/Post/${post.id}`, {
              id: post?.id,
            })
              .then((res) => {
                getPosts();
                console.log("res", res.data);
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Post has been deleted successfully.",
                  "success"
                );
              })
              .catch((error) => {
                if (error.response) {
                  console.log("Error response data:", error.response.data.title);
                  swalWithBootstrapButtons.fire(
                    "Not Deleted!",
                    `${error.response.data.title}`,
                    "error"
                  );
                } else {
                  console.log("Network error:", error.message);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    "Comment is not deleted. Network error occurred.",
                    "error"
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  };
  function handleImageError(imageErrorRef, index) {
    if (imageErrorRef.current) {
      imageErrorRef.current.src = noimage;
    }
  }
  function handleImageSuccess(row) {
    const imageErrorRef = useRef(null);
    return (
      <img
        ref={imageErrorRef}
        src={`${process.env.REACT_APP_BASE_URL}${row?.file_Location}`}
        // src={noimage}
        onError={() => handleImageError(imageErrorRef, row.id)}
        className="post-image"
      />
    );
  }
  useEffect(() => {
    getPosts();
  }, [currentPage, pageLimit]);
  const columns = [
    {
      name: "User",
      selector: (row) => [row?.userDetails?.name],
      cell: (row) => <Link to={`/users/${row?.userDetails?.id}`}>{row?.userDetails?.name}</Link>,
    },
    {
      name: "Post",
      selector: (row) => [row?.file_Location],
      button: false,
      cell: (row) => <Link to={`/posts/${row?.id}`}>{handleImageSuccess(row)}</Link>,
    },
    {
      name: "Caption",
      selector: (row) => [
        row.caption.length > 20 ? row.caption.substring(0, 20) + "..." : row.caption,
      ],
    },
    {
      name: "Location",
      selector: (row) => [row?.location],
    },
    {
      name: "No Of Comments",
      selector: (row) => [row?.post_comments],
    },

    {
      name: "No Of Likes",
      selector: (row) => [row?.post_likes || "0"],
    },
    {
      name: "ACTIONS",

      cell: (row) => (
        <div className="button-list">
          <OverlayTrigger overlay={<Tooltip> Edit</Tooltip>}>
            <EditIcon
              onClick={() => handleEditPost(row)}
              sx={{ fontSize: 30, marginLeft: "20px", cursor: "pointer" }}
            />
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip> Delete</Tooltip>}>
            <DeleteIcon
              onClick={() => handleDelete(row)}
              sx={{ fontSize: 30, cursor: "pointer" }}
            />
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Created",
      selector: (row) => [row.created],
      format: (row) => moment(row.created).format("LL"),
    },
  ];

  const tableData = {
    columns,
    data,
  };
  const handlePosts = (row) => {
    if (row?.id) {
      navigate(`/posts/${row?.id}`);
    }
  };
  if (loading) {
    return (
      <CircularProgress
        size={60} // Adjust the size here
        style={{
          margin: "auto",
          display: "block",
          color: "#1A73E8",
          position: "relative",
          top: "20rem",
        }}
      />
    );
  } else {
    return (
      <Fragment>
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <div
                      className="group"
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <MDTypography variant="h6" color="white">
                        All Posts
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        color="white"
                        onClick={handleCreate}
                        className="add"
                        style={{
                          border: "1px solid white",
                          padding: "4px 10px",
                          cursor: "pointer",
                        }}
                      >
                        Add Post
                      </MDTypography>
                    </div>
                  </MDBox>
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      paginationServer={true}
                      pagination={true}
                      paginator
                      rows={1}
                      paginationDefaultPage={currentPage}
                      paginationPerPage={pageLimit}
                      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      highlightOnHover={true}
                      pointerOnHover={true}
                      progressPending={loading}
                      onRowClicked={handlePosts}
                      onSelectedRowsChange={handlePosts}
                    />
                  </DataTableExtensions>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
        {editModal && (
          <PostModal
            opens={editModal}
            data={editData}
            isTrue={editModal}
            // id={groupData?.id}
            onUpdate={() => getPosts()}
            onClose={() => setEditModal(false)}
          />
        )}
        {opneModal && (
          <PostModal
            opens={opneModal}
            onUpdate={() => getPosts()}
            onClose={() => setOpenModal(false)}
          />
        )}
      </Fragment>
    );
  }
}
export default Posts;
