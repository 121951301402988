/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/users/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUser } from "Service/UserService";
import { Fragment, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserPosts } from "Service/UserService";
import { getUserArticles } from "Service/UserService";
import BaseURL from "Baseurl";
import {
  Button,
  Card,
  Container,
  Icon,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import backgroundImage from "assets/images/bg-profile.jpeg";
import MDAvatar from "components/MDAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import { getPost } from "Service/PostServices";
import { getPostComments } from "Service/PostServices";
import "./style.css";
import { OverlayTrigger } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import moment from "moment";
import PostModal from "./postModal";
import user from "../../assets/images/output.png";
function PostComments() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [postDetails, setPostDetails] = useState();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [showAllComments, setShowAllComments] = useState(false);
  const displayedComments = showAllComments ? comments : comments.slice(0, 5);
  const [editModal, setEditModal] = useState(false);
  const navigate = useNavigate();
  const getPostDetails = async () => {
    try {
      setLoading(true);
      const response = await getPost(id);
      if (response?.data) {
        setPostDetails(response?.data);
      }
    } catch (error) {
      // console.log(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getComments = async () => {
    try {
      setLoading(true);
      const response = await getPostComments(id);
      if (response) {
        setComments(response);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const seeAllComments = () => {
    setShowAllComments(!showAllComments);
  };
  const handleDelete = (comment_id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Comment?",
        text: "Are you sure you want to delete this Comment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`admin/PostComment/${comment_id}`, {
              id: comment_id,
            })
              .then((res) => {
                getComments();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Comment has been deleted successfully.",
                  "success"
                );
              })
              .catch((error) => {
                if (error.response) {
                  console.log("Error response data:", error.response.data.title);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    `${error.response.data.title}`,
                    "error"
                  );
                } else {
                  console.log("Network error:", error.message);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    "Comment is not deleted. Network error occurred.",
                    "error"
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  };
  const handleUpdate = () => {
    setEditModal(true);
  };
  const handleDeletePost = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Post?",
        text: "Are you sure you want to delete this Post?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`admin/Post/${id}`)
              .then((res) => {
                console.log("res", res.data);
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Post has been deleted successfully.",
                  "success"
                );
                navigate("/posts");
              })
              .catch((error) => {
                if (error.response) {
                  console.log("Error response data:", error.response.data.title);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    `${error.response.data.title}`,
                    "error"
                  );
                } else {
                  console.log("Network error:", error.message);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    "Post is not deleted. Network error occurred.",
                    "error"
                  );
                }
              });
          } catch ({ err }) {
            console.log(err);
            swalWithBootstrapButtons.fire("Deleted!", "Post is not deleted.", "error");
          }
        }
      });
  };
  useEffect(() => {
    getPostDetails();
    getComments();
  }, []);
  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        {loading ? (
          <CircularProgress
            size={60}
            style={{ margin: "auto", display: "block", color: "#1A73E8" }}
          />
        ) : postDetails && Object.keys(postDetails).length > 0 ? (
          <MDBox>
            <a
              href={`${process.env.REACT_APP_BASE_URL}${postDetails?.file_Location}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDBox mt={5} mb={3}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  position="relative"
                  minHeight="18.75rem"
                  borderRadius="xl"
                  sx={{
                    backgroundImage: ({
                      functions: { rgba, linearGradient },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.info.main, 0),
                        rgba(gradients.info.state, 0)
                      )}, url(${process.env.REACT_APP_BASE_URL}${postDetails?.file_Location})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                  }}
                />
              </MDBox>
            </a>
            <Card
              sx={{
                position: "relative",
                mt: -8,
                mx: 3,
                py: 2,
                px: 2,
              }}
            >
              <Grid container spacing={3} alignItems="center" style={{ cursor: "default" }}>
                <Grid item>
                  <Link to={`/users/${postDetails?.userDetails?.id}`}>
                    {postDetails?.userDetails?.profile_pic ? (
                      <MDAvatar
                        src={`${process.env.REACT_APP_BASE_URL}${postDetails?.userDetails?.profile_pic}`}
                        alt="profile-image"
                        size="xl"
                        shadow="sm"
                      />
                    ) : (
                      <MDAvatar src={user} alt="profile-image" size="xl" shadow="sm" />
                    )}
                  </Link>
                </Grid>
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {postDetails?.userDetails?.name}
                      </MDTypography>
                      <MDTypography
                        component={Link}
                        // to={action.route}
                        variant="body2"
                        color="secondary"
                        px={1}
                      >
                        <Tooltip
                          // title={action.tooltip}
                          placement="top"
                          onClick={handleUpdate}
                        >
                          <Icon>edit</Icon>
                        </Tooltip>
                        <Tooltip
                          // title={action.tooltip}
                          placement="top"
                          onClick={handleDeletePost}
                        >
                          <Icon>delete</Icon>
                        </Tooltip>
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {postDetails?.caption}
                    </MDTypography>
                  </MDBox>
                  <MDBox height="100%" lineHeight={1} display="grid">
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {postDetails?.location}
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {moment(postDetails?.created).fromNow()}{" "}
                    </MDTypography>
                  </MDBox>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h6" fontWeight="regular" style={{ display: "flex" }}>
                      <div>
                        <FavoriteIcon fontSize="small" />
                        <span>{postDetails?.post_likes}</span>
                      </div>
                      <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <CommentIcon fontSize="small" />
                        <span>{postDetails?.post_comments}</span>
                      </div>
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            <Card
              sx={{ mt: 3, mx: 3, py: 2, px: 2 }}
              style={{ maxHeight: "340px", overflowY: " overlay", cursor: "default" }}
            >
              <MDBox pt={2} px={2} lineHeight={1.25}>
                <MDTypography variant="h6" fontWeight="medium">
                  Comments
                </MDTypography>
              </MDBox>
              <Container className="container" style={{ "min-width": "-moz-available" }}>
                {/* <TextField
                  label="Write a Comment"
                  multiline
                  //   rows={4}
                  variant="outlined"
                  value={newComment}
                  onChange={handleCommentChange}
                  style={{ width: "100%" }}
                />
                <Button
                  sx={{
                    position: "relative",
                    mt: -5,
                    //   mx: 3,
                    //   py: 2,
                    //   px: 2,
                    float: "right",
                  }}
                  onClick={handleCommentSubmit}
                >
                  Post
                </Button> */}
                <List>
                  {displayedComments && displayedComments.length > 0 ? (
                    displayedComments.map((comment, index) => (
                      <ListItem key={index}>
                        <Link to={`/users/${comment?.userDetails?.id}`}>
                          {comment?.userDetails?.profile_pic ? (
                            <MDAvatar
                              src={`${process.env.REACT_APP_BASE_URL}${comment?.userDetails?.profile_pic}`}
                              alt="profile-image"
                              size="m"
                              shadow="sm"
                              sx={{ mt: 2 }}
                            />
                          ) : (
                            <MDAvatar
                              src={user}
                              alt="profile-image"
                              size="m"
                              shadow="sm"
                              sx={{ mt: 2 }}
                            />
                          )}
                        </Link>
                        <MDBox height="100%" mt={1} mx={1} lineHeight={1} sx={{ display: "grid" }}>
                          <MDTypography variant="button" fontWeight="medium" fontSize="12px">
                            {comment?.userDetails?.name}
                            <MDTypography
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              fontSize="12px"
                              sx={{ marginLeft: "10px" }}
                            >
                              <small>
                                on {""} {moment(comment?.created).format("MMMM DD YYYY")}
                              </small>
                            </MDTypography>
                          </MDTypography>
                          <MDTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            fontSize="12px"
                          >
                            {comment?.comments}
                          </MDTypography>
                          <div>
                            <FavoriteIcon fontSize="small" sx={{ width: "0.8em" }} />
                            <span style={{ fontSize: "14px" }}>{comment?.no_of_likes}</span>
                            <DeleteIcon
                              onClick={() => handleDelete(comment?.id)}
                              sx={{
                                fontSize: 10,
                                cursor: "pointer",
                                width: "0.8em",
                                marginLeft: "1em",
                              }}
                            />
                          </div>
                        </MDBox>
                      </ListItem>
                    ))
                  ) : (
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                      fontSize="12px"
                    >
                      No comments
                    </MDTypography>
                  )}
                </List>
                {comments.length > 5 && (
                  <Button variant="text" onClick={seeAllComments}>
                    {showAllComments ? "See Less" : "See More"}
                  </Button>
                )}
              </Container>
            </Card>
          </MDBox>
        ) : (
          <MDTypography>NOT FOUND</MDTypography>
        )}

        <Footer />
      </DashboardLayout>
      {editModal && (
        <PostModal
          opens={editModal}
          data={postDetails}
          isTrue={editModal}
          // id={groupData?.id}
          onUpdate={() => getPostDetails()}
          onClose={() => setEditModal(false)}
        />
      )}
    </Fragment>
  );
}

export default PostComments;
