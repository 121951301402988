/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Fragment, useState } from "react";

// react-router-dom components
import { Link, json, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BaseURL, { setToken } from "../../../Baseurl.js";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { setUser } from "../../../Redux/Slices/AuthSlice.js";
import { useDispatch } from "react-redux";
import Alert from "react-bootstrap/Alert";

//import BackgroundAnimation from "./BackgroundAnimation.js";

import { MyComponent } from "./BackgroundAnimation.js";
import { toast } from "react-toastify";
import Cover from "../reset-password/cover/index.js";
import { CircularProgress } from "@mui/material";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [err, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [user, setuser] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [forget, setForget] = useState(false);

  const handleForget = () => {
    setForget(true);
  };

  const handleSubmit = (e) => {
    const err = {};
    if (!email) {
      err.email = "Email Is Required";
    }
    if (!password) {
      err.password = "Password Is Required";
    }
    setErrors(undefined);
    e.preventDefault();
    setLoading(true);
    if (Object.keys(err).length === 0) {
      BaseURL.post(
        "/User/admin/login",
        {
          email,
          password,
        },
        {
          headers: {
            " Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          setEmail("");
          setpassword("");
          toast.success("Logined Successfully");
          JSON.stringify(localStorage.setItem("user_details", response));
          setToken(response.data?.token);
          const userData = response.data?.data;
          setuser(true);
          dispatch(setUser(userData));
          navigate("/");
          //window.location.reload(true);
        })
        .catch((err) => {
          setError(err.response?.data?.detail);
          console.log(err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setErrors(err);
  };
  console.log(err);
  return (
    <Fragment>
      {!forget ? (
        <BasicLayout image={bgImage}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="error" // Change the background color to #FB1414
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Sign in
              </MDTypography>
              <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid> */}
              </Grid>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              {err && (
                <Alert variant="danger">
                  {" "}
                  <small>{err}</small>
                </Alert>
              )}

              <MDBox component="form" role="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    name="email"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div>
                    {email ? (
                      <div></div>
                    ) : (
                      <div className="error">
                        {errors.email && (
                          <span className="error" style={{ color: "red" }}>
                            {errors.email}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    name="password"
                    fullWidth
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  <div>
                    {password ? (
                      <div></div>
                    ) : (
                      <div className="error">
                        {errors.password && (
                          <span className="error" style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography> */}
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="error" type="submit" fullWidth>
                    {loading ? (
                      <CircularProgress size={20} style={{ color: "#1A73E8", margin: "auto" }} />
                    ) : (
                      ""
                    )}
                    sign in
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  {/* <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
              <br></br> */}
                  <MDTypography variant="button" color="text">
                    <MDTypography variant="button" color="info" fontWeight="medium" textGradient>
                      <span onClick={handleForget}>Forgot password</span>
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </BasicLayout>
      ) : (
        <Cover />
      )}
    </Fragment>
  );
}

export default Basic;
