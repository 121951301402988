import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Badge, Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import GroupService from "Service/Group/GroupService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsers } from "Service/UserService";
import { createEvent } from "Service/EventServices";
import { EditEvent } from "Service/EventServices";
import MapModal from "./mapModal";
import Select from "react-select";
import moment from "moment";
//TODO (ahmed): Need to add validation
function EventModal({ data, opens, onClose, isTrue, id, onUpdate }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [editModal, setEditModal] = useState(false);

  EventModal.propTypes = {
    data: PropTypes.object, // You can specify the shape of this object more precisely if needed
    opens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isTrue: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  };

  console.log("group", id);

  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [about, setAbout] = useState("");
  const [locationLat, setLocationLat] = useState(0);
  const [locationLong, setLocationLong] = useState(0);
  const [volunteerLimit, setVolunteerLimit] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [location, setLocation] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [usersList, setUsersList] = useState();
  const [userId, setUserId] = useState();
  //const [id, setId] = useState("");

  useEffect(() => {
    setLocation(data?.event_location);
    setAbout(data?.about);
    setDescription(data?.description);
    if (data?.end_date) setEndDate(moment(data.end_date).format("DD/MM/YYYY"));
    setEndTime(data?.end_time);
    setEventName(data?.event_name);
    if (data?.location_lat) setLocationLat(data?.location_lat);
    if (data?.location_long) setLocationLong(data?.location_long);
    setUserId(data?.userDetails?.id);
    if (data?.start_date) setStartDate(moment(data.start_date).format("DD/MM/YYYY"));
    setStartTime(data?.start_time);
    setVolunteerLimit(data?.volunteer_limit);
    setCoverImage(data?.fileLocation);
  }, [data]);

  // Error message
  const [errors, setError] = useState({});
  const [errorMessage, setErrors] = useState(undefined);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [searchKey, setShopSearchKey] = useState("");
  const handleClose = () => {
    setError({});
    setErrors();
    onClose();
  };
  const getUsers = async () => {
    try {
      const response = await getAllUsers(1, 1000, searchKey);
      if (response?.data && response?.data?.length > 0) {
        let _tempData = response?.data ?? [];
        console.log(_tempData);
        let _sortedData = _tempData.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
        setUsersList(_sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [searchKey]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose]);

  const notify = () =>
    toast(isTrue ? "Event Updated Successfully!" : "Event Created Successfully!");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!eventName) {
      errors.eventName = "Event Name Is Required";
    }
    if (!about) {
      errors.about = "About Is Required";
    }
    if (!description) {
      errors.description = "Description Is Required";
    }
    if (!startDate) {
      errors.startDate = "Start Date Is Required";
    }
    if (!endDate) {
      errors.endDate = "End Date Is Required";
    }
    if (!startTime) {
      errors.startTime = "Start Time Is Required";
    }
    if (!endTime) {
      errors.endTime = "End Time Is Required";
    }
    if (!location) {
      errors.location = "Location Is Required";
    }
    if (!volunteerLimit) {
      errors.volunteerLimit = "Volunteer Limit Is Required";
    }
    if (!coverImage) {
      errors.coverImage = "Cover image Is Required";
    }
    if (!isTrue) {
      if (!userId) {
        errors.userId = "User Is Required";
      }
    }
    console.log(errors);

    setLoadingCategory(true);

    setErrors(undefined);
    if (Object.keys(errors).length === 0) {
      try {
        if (isTrue) {
          const formData = {
            Event_name: eventName,
            Event_location: location,
            Start_date: startDate,
            End_date: endDate,
            Description: description,
            About: about,
            location_lat: locationLat,
            location_long: locationLong,
            Volunteer_limit: volunteerLimit,
            Start_time: startTime,
            End_time: endTime,
          };
          const res = await EditEvent(data?.id, formData);
          console.log(res);
          notify();
          handleClose();
        } else {
          const formData = new FormData();
          formData.append("Event_name", eventName);
          formData.append("Event_location", location);
          formData.append("Start_date", startDate);
          formData.append("End_date", endDate);
          formData.append("Description", description);
          formData.append("About", about);
          if (locationLat) formData.append("location_lat", locationLat);
          if (locationLong) formData.append("location_long", locationLong);
          formData.append("Volunteer_limit", volunteerLimit);
          formData.append("Start_time", startTime);
          formData.append("End_time", endTime);
          formData.append("USERID", userId);
          formData.append("fileData", coverImage);
          const res = await createEvent(formData);
          console.log(res);
          notify();
          handleClose();
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          setErrors(err.response.data.message);
        } else {
          setErrors("Unknown error occurred");
        }
      } finally {
        setLoadingCategory(false);
        onUpdate();
        // Set loading to false, not true
      }
    }
    setError(errors);
    setLoadingCategory(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  console.log(data);

  const updateTime = (time) => {
    // const timeSplit = time.split(":");
    // if (parseInt(timeSplit[0]) == 12) {
    //   time = `${timeSplit[0]}:${timeSplit[1]} PM`;
    // } else if (parseInt(timeSplit[0]) > 12) {
    //   time = `${timeSplit[0] - 12}:${timeSplit[1]} PM`;
    // } else if (parseInt(timeSplit[0]) == 0) {
    //   time = `12:${timeSplit[1]} AM`;
    // } else if (parseInt(timeSplit[0]) < 12) {
    //   time = `${timeSplit[0]}:${timeSplit[1]} AM`;
    // }
    // console.log(time);
    return time;
  };
  return (
    <div className="addCategory">
      <Modal show={opens} size="lg" backdrop="static">
        <Modal.Header closeButton className="add_categorys" onClick={handleClose}>
          <Modal.Title>{isTrue ? <p> Edit Event</p> : <p> Add Event</p>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" mb-3 updatedoctor">
            <label className="pt-3">Event Name</label>
            <input
              type="text"
              placeholder="Enter Event Name"
              className="form-control"
              name="eventName"
              onChange={(e) => setEventName(e.target.value)}
              defaultValue={data?.event_name}
            />
            <div>
              {eventName ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.eventName && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.eventName}
                    </span>
                  )}
                </div>
              )}
            </div>
            <label className="pt-3">Location</label>
            <input
              type="text"
              placeholder="Enter Location"
              className="form-control"
              name="location"
              onChange={(e) => setLocation(e.target.value)}
              defaultValue={data?.event_location}
            />
            {location ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.location && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.location}
                  </span>
                )}
              </div>
            )}
            <label className="pt-3">Description</label>
            <input
              type="text"
              placeholder="Enter Description"
              className="form-control"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              defaultValue={data?.description}
            />
            {description ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.description && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.description}
                  </span>
                )}
              </div>
            )}
            <label className="pt-3">About</label>
            <input
              type="text"
              placeholder="Enter About"
              className="form-control"
              name="about"
              onChange={(e) => setAbout(e.target.value)}
              defaultValue={data?.about}
            />
            {about ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.about && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.about}
                  </span>
                )}
              </div>
            )}
            <label className="pt-3">Start Date</label>
            <input
              type="date"
              placeholder="Enter Start Date"
              className="form-control"
              name="eventName"
              onChange={(e) => setStartDate(moment(e.target.value).format("DD/MM/YYYY"))}
              defaultValue={moment(data?.start_date).format("YYYY-MM-DD")}
            />
            {startDate ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.startDate && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.startDate}
                  </span>
                )}
              </div>
            )}
            <label className="pt-3">End Date</label>
            <input
              type="date"
              placeholder="Enter End Date"
              className="form-control"
              name="eventName"
              onChange={(e) => setEndDate(moment(e.target.value).format("DD/MM/YYYY"))}
              defaultValue={moment(data?.end_date).format("YYYY-MM-DD")}
            />
            {endDate ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.endDate && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.endDate}
                  </span>
                )}
              </div>
            )}
            <label className="pt-3">Start Time</label>
            <input
              type="time"
              placeholder="Enter Start Time"
              className="form-control"
              name="eventName"
              onChange={(e) => setStartTime(updateTime(e.target.value))}
              defaultValue={data?.start_time}
            />
            {startTime ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.startTime && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.startTime}
                  </span>
                )}
              </div>
            )}
            <label className="pt-3">End Time</label>
            <input
              type="time"
              placeholder="Enter End Time"
              className="form-control"
              name="eventName"
              onChange={(e) => setEndTime(updateTime(e.target.value))}
              defaultValue={data?.end_time}
            />
            {endTime ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.endTime && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.endTime}
                  </span>
                )}
              </div>
            )}
            <label className="pt-3">Volunteer Limit</label>
            <input
              type="number"
              placeholder="Enter Volunteer Limit"
              className="form-control"
              name="eventName"
              onChange={(e) => setVolunteerLimit(e.target.value)}
              defaultValue={data?.volunteer_limit}
            />
            {volunteerLimit ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.volunteerLimit && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.volunteerLimit}
                  </span>
                )}
              </div>
            )}
            {!isTrue ? (
              <>
                <label className="pt-3">Cover Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={(e) => setCoverImage(e.target.files[0])}
                />
                {coverImage ? (
                  <div></div>
                ) : (
                  <div className="error">
                    {errors.coverImage && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.coverImage}
                      </span>
                    )}
                  </div>
                )}
                {/* <img
                  src={`${baseURL}${data?.file_Location}`}
                  alt="image"
                  width="250px"
                  height="150px"
                  style={{ marginTop: "10px" }}
                /> */}
              </>
            ) : null}
            {!isTrue ? (
              <>
                <label className="pt-3">User</label>
                <Select
                  closeMenuOnSelect={true}
                  options={usersList}
                  name="user"
                  className="form-control"
                  isSearchable={true}
                  onInputChange={(shopSearchKey) => setShopSearchKey(shopSearchKey)}
                  onChange={(selectedOption) => {
                    setUserId(selectedOption.value);
                  }}
                />
                {userId ? (
                  <div></div>
                ) : (
                  <div className="error">
                    {errors.userId && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.userId}
                      </span>
                    )}
                  </div>
                )}
              </>
            ) : null}

            <label className="pt-3">Location</label>
            <input
              type="text"
              placeholder="Enter About"
              className="form-control"
              name="about"
              value={locationLat + "," + locationLong}
              disabled
            />
            <div className="map-btn-div">
              <button className="btn btn-success" onClick={() => setEditModal(true)}>
                Set Map
              </button>
            </div>
            {about ? (
              <div></div>
            ) : (
              <div className="error">
                {errors.about && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.about}
                  </span>
                )}
              </div>
            )}

            <div className="pt-3"></div>
            {errorMessage ? (
              <div>
                <Badge pill bg="danger">
                  {errorMessage.toString()}
                </Badge>
              </div>
            ) : (
              <></>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={loadingCategory}>
            {loadingCategory ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span>{isTrue ? <span>Update </span> : <span>Add</span>}</span>
            )}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {editModal && (
        <MapModal
          opens={editModal}
          data={data}
          isTrue={editModal}
          // id={groupData?.id}
          onClose={() => setEditModal(false)}
          setLocationLat={setLocationLat}
          setLocationLong={setLocationLong}
        />
      )}
    </div>
  );
}

export default EventModal;
