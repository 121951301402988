/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components

import user_image from "../../../assets/images/output.png";

// Data

// Images
import { Card } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { changeEmail, changePassword, getAdminProfile } from "Service/profileService";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { Fragment, useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "./profile.css";
import { json } from "react-router-dom";

function ProfileDetails() {
  const [loading, setLoading] = useState(false);
  const labels = [];
  const values = [];

  const [newPassword, setNewPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [user, setUser] = useState();
  const [email, setEmail] = useState("");
  const [errors, setError] = useState({});
  const [errorMessage, setErrors] = useState(undefined);
  const userWithoutProfilePic = { ...user };
  delete userWithoutProfilePic.profile_pic;
  const propertiesToDelete = [
    "posts",
    "articles",
    "certificates",
    "trainings",
    "mutual_connections",
    "mutual_connection_list",
    "connection_status",
    "is_connected",
    "connection_id",
    "can_accept",
    "position",
    "isVerified",
    "profile_pic",
    "about",
    "no_of_posts",
    "no_of_articles",
    "no_of_connections",
  ];
  const filteredUser = { ...user };
  for (const prop of propertiesToDelete) {
    delete filteredUser[prop];
  }
  Object.keys(filteredUser).forEach((el) => {
    if (el.match(/[A-Za-z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Za-z\s]+/));
      const newElement = el
        .replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`)
        .replace(/_/g, " ");
      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  Object.values(filteredUser).forEach((el) => values.push(el));

  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));
  const id = JSON.parse(localStorage.getItem("user")).id;
  const getProfile = async () => {
    setLoading(true);
    if (id) {
      try {
        const res = await getAdminProfile(id);
        setUser(res?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const notify = (message) => toast.error(message);
  const updatePassword = async () => {
    try {
      const res = await changePassword(oldPassword, newPassword);
      if (res) {
        notify("Password Changed Successfully!");
      }
      getProfile();
      setNewPassword("");
      setOldPassword("");
    } catch (error) {
      notify(error?.response?.data);
    }
  };
  const updateEmail = async () => {
    try {
      const res = await changeEmail(email);
      if (res) {
        toast("Email Updated Successfully!");
      }
      getProfile();
      setEmail("");
    } catch (error) {
      if (error?.response?.data?.errors) {
        notify(error?.response?.data?.errors?.email[0]);
      } else {
        notify(error?.response?.data);
      }
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        {loading ? (
          <CircularProgress
            size={60}
            style={{ margin: "auto", display: "block", color: "#1A73E8" }}
          />
        ) : (
          <MDBox>
            <a
              //href={`${process.env.REACT_APP_BASE_URL}${postDetails?.file_Location}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDBox mt={5} mb={3}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  position="relative"
                  minHeight="18.75rem"
                  borderRadius="xl"
                  sx={{
                    backgroundImage: ({
                      functions: { rgba, linearGradient },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.info.main, 0),
                        rgba(gradients.info.state, 0)
                      )}, url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                  }}
                />
              </MDBox>
            </a>
            <Card
              sx={{
                position: "relative",
                mt: -8,
                mx: 3,
                py: 2,
                px: 2,
              }}
            >
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                {user?.profile_pic ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${user?.profile_pic}`}
                    width="100px"
                    alt="user"
                  />
                ) : (
                  <img src={user_image} width="100px" alt="image" />
                )}
              </MDBox>
              <Row>
                <div className="col-md-6 mt-5">
                  <MDBox>{renderItems.slice(0, 5)}</MDBox>
                </div>
                <div className="col-md-6 mt-5">
                  <MDBox>{renderItems.slice(5)}</MDBox>
                </div>
              </Row>
            </Card>
          </MDBox>
        )}
        <MDBox mt={5} mb={3}>
          <Card
            sx={{
              position: "relative",
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Row>
              <div className="col-md-8 ">
                <Form.Label id="emailid">Update Email Address</Form.Label>
                <Form.Control
                  defaultValue={email}
                  type="email"
                  name="email"
                  placeholder="example@email.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="col-md-4 pt-4">
                <Button
                  variant="danger"
                  style={{ marginTop: " 13px", width: " 100%" }}
                  onClick={updateEmail}
                >
                  Update
                </Button>
              </div>
            </Row>
          </Card>
        </MDBox>
        <MDBox mt={5} mb={3}>
          <Card
            sx={{
              position: "relative",
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Row>
              <div className="col-md-6 ">
                <Form.Label id="emailid">Old Password</Form.Label>
                <Form.Control
                  defaultValue={oldPassword}
                  type="password"
                  placeholder="Enter Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="col-md-6 ">
                <Form.Label id="emailid">New Password</Form.Label>
                <Form.Control
                  defaultValue={newPassword}
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                />
              </div>
            </Row>
            <div className="col-md-4 pt-4" style={{ alignSelf: "center" }}>
              <Button
                variant="danger"
                style={{ marginTop: " 13px", width: " 100%" }}
                onClick={updatePassword}
              >
                Change Password
              </Button>
            </div>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </Fragment>
  );
}

export default ProfileDetails;
