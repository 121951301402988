import BaseURL from "../Baseurl";
const hasToken = localStorage.getItem("token");

export const getAllUsers = async (page, limit, search = "", userType = "normal") => {
  if (!hasToken) {
    return;
  }
  try {
    const data = await BaseURL.get(
      `/admin/User?page=${page}&limit=${limit}&search=${search}&userType=${userType}`
    );
    return data?.data;
  } catch (error) {
    console.error("API request failed:", error);
  }
};

export const getUser = async (id) => {
  try {
    const { data } = await BaseURL.get(`admin/User/user_details/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addVerification = async (id) => {
  try {
    const { data } = await BaseURL.get(`/admin/User/addVerification/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const removeVerification = async (id) => {
  try {
    const { data } = await BaseURL.get(`/admin/User/removeVerification/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserPosts = async (page, limit, user_id) => {
  try {
    const { data } = await BaseURL.get(
      `Post/user_posts?page=${page}&limit=${limit}&userId=${user_id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserArticles = async (page, limit, user_id) => {
  if (!hasToken) {
    return;
  }
  try {
    const { data } = await BaseURL.get(
      `Article/user_articles?page=${page}&limit=${limit}&userId=${user_id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateUser = async ({ data, id }) => {
  try {
    await BaseURL.putForm(`admin/User/updateProfile/${id}`, data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserConnectionList = async ({ user_id, page, limit }) => {
  try {
    const { data } = await BaseURL.get(
      `admin/User/get_connections/${user_id}?page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getUserMetaData = async (user_id) => {
  try {
    const { data } = await BaseURL.get(`admin/User/user_device_details/${user_id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
