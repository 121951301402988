import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Badge, Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import ArticleService from "Service/Article/ArticleService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsers } from "Service/UserService";
import Select from "react-select";

import { UpdateUser } from "Service/UserService";

function UserUpdates({ data, opens, onClose, isTrue }) {
  const baseURL = process.env.REACT_APP_BASE_URL;

  UserUpdates.propTypes = {
    // You can specify the shape of this object more precisely if needed
    data: PropTypes.string,
    opens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    // onUpdate: PropTypes.func.isRequired,
    isTrue: PropTypes.bool.isRequired,
    // id: PropTypes.number.isRequired,
  };

  console.log("data", data);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [preLang, setpreLang] = useState("");
  const [country, setCountry] = useState("");
  const [about, setAbout] = useState("");
  const [file, setFile] = useState("");
  const [userType, setUserType] = useState("");
  const [nationalid, setNationalId] = useState("");
  const [removePic, setRemovePic] = useState(false);

  useEffect(() => {
    setName(data?.fullName);
    setEmail(data?.email);
    setpreLang(data?.Language);
    setCountry(data?.country);
    setAbout(data?.about);
    setFile(data?.fileData);
    setUserType(data?.User_role);
    setNationalId(data?.nationalid);
  }, [data]);

  //const [id, setId] = useState("");

  // Error message
  const [errors, setError] = useState({});
  const [errorMessage, setErrors] = useState(undefined);
  const [loadingCategory, setLoadingCategory] = useState(false);

  const handleClose = () => {
    setError({});
    setError("");
    onClose();
  };

  const [groupAdmin, setGroupAdmin] = useState([]);
  const getOwner = async () => {
    await getAllUsers(1, 1000)
      .then((res) => {
        setGroupAdmin(res.data);
        console.log("groupadmin", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOwner();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose]);

  const notify = () =>
    toast(isTrue ? "User Updated Successfully!" : "Article Created Successfully!");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const errors = {};

    if (!name) {
      errors.name = "Name Is Required";
    }

    setLoadingCategory(true);

    setErrors(undefined);
    if (Object.keys(errors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("country", country);
        formData.append("Preferred_lang", preLang);
        formData.append("User_role", userType);
        formData.append("NATIONAL_ID", nationalid);
        formData.append("about", about);
        formData.append("fileData", file);
        formData.append("Delete_profile_pic", removePic);
        await UpdateUser({ data: formData, id: data?.id })
          .then((res) => {
            notify();
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              toast.error(err.response.data);
            } else {
              console.log(err);
              setErrors("Unknown error occurred");
            }
          });
        handleClose();
      } finally {
        // window.location.reload(true);
        getOwner();
        setLoadingCategory(false);

        // Set loading to false, not true
      }
    }
    setError(errors);
    setLoadingCategory(false);
  };

  const [shopSearchResult, setShopSearchResult] = useState([]);
  const [shopSearchKey, setShopSearchKey] = useState("");
  const [loadingshopSearch, setLoadingShopSearch] = useState(false);

  const shopSearchData = async () => {
    setLoadingShopSearch(true);
    await getAllUsers(1, 1000)
      .then((res) => {
        let _tempData = res.data ?? [];
        console.log(_tempData);
        let _sortedData = _tempData.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        });

        setShopSearchResult(_sortedData);
      })
      .finally(() => {
        setLoadingShopSearch(false);
      });
  };

  useEffect(() => {
    shopSearchData();
  }, [shopSearchKey]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="addCategory">
      <Modal show={opens} size="lg" backdrop="static">
        <Modal.Header closeButton className="add_categorys" onClick={handleClose}>
          <Modal.Title>{isTrue ? <p> Edit User</p> : <p> Add Article</p>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" mb-3 updatedoctor">
            <label className="pt-3">Name</label>
            <input
              type="text"
              placeholder="Enter Name"
              className="form-control"
              name="Name"
              onChange={(e) => setName(e.target.value)}
              defaultValue={data?.fullName}
            />
            <div>
              {name ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors.name && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.name}
                    </span>
                  )}
                </div>
              )}
            </div>
            <label className="pt-3">Preferred Language</label>
            {/* <input
              type="text"
              placeholder="Enter Preferred_lang"
              className="form-control"
              name="Preferred Lang"
              onChange={(e) => setpreLang(e.target.value)}
              defaultValue={data?.Language}
            /> */}
            <select
              onChange={(e) => setpreLang(e.target.value)}
              className="form-control"
              defaultValue={data?.Language}
            >
              <option> - - Select Language - - </option>
              <option value="English">English</option>
              <option value="Arabic">Arabic</option>
            </select>
            <label className="pt-3">Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              className="form-control"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={data?.email}
            />
            <label className="pt-3">Country</label>
            <input
              type="text"
              placeholder="Enter Country"
              className="form-control"
              name="Country"
              onChange={(e) => setCountry(e.target.value)}
              defaultValue={data?.country}
            />
            <label className="pt-3">User Type</label>
            <select
              className="form-control"
              onChange={(e) => setUserType(e.target.value)}
              defaultValue={data?.User_role}
            >
              <option value="normal">Normal</option>
              <option value="supervisor">supervisor</option>
              <option value="guest">guest</option>
            </select>
            <label className="pt-3">NATIONAL ID</label>
            <input
              type="number"
              placeholder="Enter National Id"
              className="form-control"
              name="nationalid"
              onChange={(e) => setNationalId(e.target.value)}
              defaultValue={data?.National_Id}
            />
            <label className="pt-3">About</label>
            <input
              type="text"
              placeholder="Enter About"
              className="form-control"
              name="About"
              onChange={(e) => setAbout(e.target.value)}
              defaultValue={data?.about}
            />
            <label className="pt-3">File</label>
            <input
              type="file"
              className="form-control"
              name="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
            {/* {file && (
              <>
                <label className="pt-3">Remove Profile Pic</label>
                <input
                  type="checkbox"
                  onChange={(e) => setRemovePic(e.target.checked)}
                  checked={removePic}
                  style={{ marginLeft: "1rem" }}
                />
              </>
            )} */}
            {/* {isTrue ? (
              <img
                src={`${baseURL}${data?.fileData}`}
                alt="image"
                width="250px"
                height="150px"
                style={{ marginTop: "10px" }}
              />
            ) : null} */}

            <div className="pt-3"></div>
            {errorMessage ? (
              <div>
                <Badge pill bg="danger">
                  {errorMessage.toString()}
                </Badge>
              </div>
            ) : (
              <></>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={loadingCategory}>
            {loadingCategory ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span>{isTrue ? <span>Update </span> : <span>Add</span>}</span>
            )}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserUpdates;
