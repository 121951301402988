import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, CircularProgress, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";
import { getAllEvents, DeleteEvent } from "Service/EventServices";
import { Fragment, useEffect, useState } from "react";
import moment from "moment/moment";
import EventModal from "./eventModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import BaseURL from "Baseurl";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import noimage from "../../assets/images/noimagepng.png";
import { redirectToUser } from "layouts/users";

function Events() {
  const navigate = useNavigate();

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Make sure to adjust the type as needed
    name: PropTypes.string.isRequired,
    // email: PropTypes.string.isRequired,
  };
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  Job.propTypes = {
    title: PropTypes.string.isRequired, // Make sure to adjust the type as needed
  };

  const [totalRows, setTotalRows] = useState(0);
  const [opneModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1
  );
  const [pageLimit, setPerPage] = useState(
    parseInt(searchParams.get("limit")) ? parseInt(searchParams.get("limit")) : 10
  );
  const getEvents = async () => {
    try {
      const response = await getAllEvents({ page: currentPage, limit: pageLimit });
      if (response?.data) {
        let events = response?.data?.map((itm) => itm);
        setData(events);
        setTotalRows(response?.total_count);
        console.log(response.total_count);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvents();
  }, [currentPage, pageLimit]);

  const handleCreate = () => {
    setOpenModal(true);
  };
  const handleEditEvent = (data) => {
    if (data) {
      setEditModal(true);
      setEditData(data);
    }
  };
  const handleDelete = (item) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Event?",
        text: "Are you sure you want to delete this Event?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            DeleteEvent(item.id).then((res) => {
              getEvents();
              console.log("res", res);
            });
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Event has been deleted successfully.",
            "success"
          );
        }
      });
  };

  const columns = [
    {
      name: "User",
      selector: (row) => [row?.userDetails?.name],
      cell: (row) => (
        <div onClick={() => redirectToUser(row?.userDetails?.id)}>{row?.userDetails?.name}</div>
      ),
    },
    {
      name: "Cover",
      selector: (row) => [row?.event_name],
      cell: (row) => (
        <div>
          {row?.fileLocation ? (
            <img
              src={`${process.env.REACT_APP_BASE_URL}${row?.fileLocation}`}
              width="80px"
              height="80px"
              style={{ objectFit: "cover" }}
            />
          ) : (
            <img src={noimage} width="80px" height="80px" />
          )}
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => [row.event_name],
      cell: (row) => <div>{row?.event_name}</div>,
    },
    {
      name: "Location",
      selector: (row) => [row?.event_location],
      cell: (row) => <div>{row?.event_location}</div>,
    },
    {
      name: "Volunteer Limit",
      selector: (row) => [row?.volunteer_limit],
      cell: (row) => <div>{row?.volunteer_limit}</div>,
    },

    {
      name: "Volunteer Count",
      selector: (row) => [row?.volunteer_count],
      cell: (row) => <div>{row?.volunteer_count}</div>,
    },
    {
      name: "Start Date",
      selector: (row) => [row?.start_date],
      cell: (row) => <div>{moment(row.start_date).format("LL")}</div>,
    },
    {
      name: "End Date",
      selector: (row) => [row?.end_date],
      cell: (row) => <div>{moment(row.end_date).format("LL")}</div>,
    },
    {
      name: "ACTIONS",
      // selector: (row) => [row.ACTIONS],

      cell: (row) => (
        <div className="button-list">
          <OverlayTrigger overlay={<Tooltip> Edit</Tooltip>}>
            <EditIcon
              onClick={() => handleEditEvent(row)}
              sx={{ fontSize: 30, marginLeft: "20px", cursor: "pointer" }}
            />
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip> Delete</Tooltip>}>
            <DeleteIcon
              onClick={() => handleDelete(row)}
              sx={{ fontSize: 30, cursor: "pointer" }}
            />
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };
  const handleEvents = (row) => {
    if (row?.id) {
      navigate(`/events/${row?.id}`);
    }
  };
  if (loading) {
    return (
      <CircularProgress
        size={60} // Adjust the size here
        style={{
          margin: "auto",
          display: "block",
          color: "#1A73E8",
          position: "relative",
          top: "20rem",
        }}
      />
    );
  } else {
    return (
      <Fragment>
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <div
                      className="group"
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <MDTypography variant="h6" color="white">
                        All Events
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        color="white"
                        onClick={handleCreate}
                        className="add"
                        style={{
                          border: "1px solid white",
                          padding: "4px 10px",
                          cursor: "pointer",
                        }}
                      >
                        Add Event
                      </MDTypography>
                    </div>
                  </MDBox>
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      data={data}
                      columns={columns}
                      paginationServer={true}
                      pagination={true}
                      paginator
                      paginationDefaultPage={currentPage}
                      paginationPerPage={pageLimit}
                      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={(p) => {
                        setPerPage(p);
                        setCurrentPage(1);
                      }}
                      onChangePage={(p) => setCurrentPage(p)}
                      onRowClicked={handleEvents}
                      highlightOnHover={true}
                      pointerOnHover={true}
                    />
                  </DataTableExtensions>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </DashboardLayout>
        {editModal && (
          <EventModal
            opens={editModal}
            data={editData}
            isTrue={editModal}
            // id={groupData?.id}
            onUpdate={() => getEvents()}
            onClose={() => setEditModal(false)}
          />
        )}
        {opneModal && (
          <EventModal
            opens={opneModal}
            onUpdate={() => getEvents()}
            onClose={() => setOpenModal(false)}
          />
        )}
      </Fragment>
    );
  }
}
export default Events;
