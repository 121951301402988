/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Group from "layouts/tables";
import GroupAdmin from "layouts/tables copy";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import { Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types"; // Import PropTypes

// @mui icons
import Icon from "@mui/material/Icon";
import Users from "layouts/users";
import UserInfo from "layouts/users/userDetails";
import Posts from "layouts/posts";
import Events from "layouts/events";
import MainControl from "layouts/MainControl/MainControl";

import Article from "layouts/Artcle/Artcle";
import PostComments from "layouts/posts/postComments";
import EventDetail from "layouts/events/eventDetail";

import NotifyList from "layouts/notifications/NotifyList";
import UserConnectionList from "layouts/users/userConnectionList";

import GroupDetails from "layouts/tables/GroupDetails";
import ArticleDetails from "layouts/Artcle/articleDetails";
import Reports from "layouts/Reports/index";
import Admin from "layouts/admin/Admin";
import AdminDetailsPage from "layouts/admin/AdminDetailsPage";

import ProfileDetails from "layouts/authentication/sign-in/ProfileDetails";
import Error404 from "examples/NotFound/Error404";
import PlainInfo from "examples/NotFound/plainPage";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
    role: "admin",
  },

  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    route: "/admin",
    component: <Admin />,
    role: "admin",
  },
  {
    type: "collapse",
    name: "Group",
    key: "group",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/group",
    component: <Group />,
    role: "admin",
  },
  {
    type: "collapse",
    name: "Group Admin",
    key: "groupadmin",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/groupadmin",
    component: <GroupAdmin />,
    role: "admin",
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/users",
    component: <Users />,
    role: "admin",
  },
  {
    name: "Users",
    key: "users",
    route: "/users/:id",
    component: <UserInfo />,
    role: "admin",
  },

  {
    name: "Group",
    key: "group",
    route: "/group/:id",
    component: <GroupDetails />,
    role: "admin",
  },

  {
    name: "admin",
    key: "admin",
    route: "/admin/:id",
    component: <AdminDetailsPage />,
    role: "admin",
  },

  {
    name: "Users",
    key: "users",
    route: "/users/:id/connections",
    component: <UserConnectionList />,
    role: "admin",
  },

  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  {
    type: "collapse",
    name: "Posts",
    key: "posts",
    icon: <Icon fontSize="small">add_to_photos</Icon>,
    route: "/posts",
    component: <Posts />,
    role: "admin",
  },
  {
    name: "Posts",
    key: "comments",
    route: "/posts/:id",
    component: <PostComments />,
    role: "admin",
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/events",
    component: <Events />,
    role: "admin",
  },
  {
    name: "Events",
    key: "event_details",
    route: "/events/:id",
    component: <EventDetail />,
    role: "admin",
  },
  {
    type: "collapse",
    name: "Article",
    key: "article",
    icon: <Icon fontSize="small">book</Icon>,
    route: "/article",
    component: <Article />,
    role: "admin",
  },
  {
    name: "Article",
    key: "article",
    route: "/article/:id",
    component: <ArticleDetails />,
    role: "admin",
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    role: "admin",
  },
  {
    type: "collapse",
    name: "Notification List",
    key: "notificationlist",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifylist",
    component: <NotifyList />,
    role: "admin",
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">report</Icon>,
    route: "/reports",
    component: <Reports />,
    role: "admin",
  },
  // {
  //   type: "collapse",
  //   name: "Main Control",
  //   key: "maincontrol",
  //   icon: <Icon fontSize="small">settings</Icon>,
  //   route: "/maincontrol",
  //   component: <MainControl />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
    role: "admin",
  },
  {
    route: "/profile",
    component: <ProfileDetails />,
    role: "admin",
    key: "profile",
  },
  {
    route: "*",
    component: <Error404 />,
  },
  {
    route: "/info",
    component: <PlainInfo />,
  },

  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
