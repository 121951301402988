import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useMapEvents, MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";

//TODO (ahmed): Need to add validation
function MapModal({ opens, onClose, setLocationLong, setLocationLat }) {
  MapModal.propTypes = {
    opens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setLocationLong: PropTypes.func.isRequired,
    setLocationLat: PropTypes.func.isRequired,
  };
  const [markersArray, setMarkersArray] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(null);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose]);

  const LocationFinderDummy = () => {
    const map = useMapEvents({
      click(e) {
        console.log(e.latlng);
        setMarkersArray([e.latlng?.lat, e.latlng?.lng]);
        setLocationLat(e.latlng?.lat);
        setLocationLong(e.latlng?.lng);
      },
    });
    return null;
  };

  useEffect(() => {
    console.log(markersArray);
  }, [markersArray]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setDefaultLocation([latitude, longitude]);
  }

  function error() {
    setDefaultLocation([24.449025, 54.330295]);
  }
  useEffect(() => {
    console.log(defaultLocation);
  }, [defaultLocation]);

  return (
    <div className="addCategory">
      <Modal show={opens} size="xl" backdrop="static">
        <Modal.Header closeButton className="add_categorys" onClick={handleClose}>
          <Modal.Title>{<p>Map</p>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {defaultLocation ? (
            <MapContainer
              key={1}
              center={defaultLocation}
              zoom={15}
              style={{ height: "500px", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {markersArray ? <Marker position={markersArray}></Marker> : <></>}
              <LocationFinderDummy />
            </MapContainer>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MapModal;
