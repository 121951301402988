/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Swal from "sweetalert2";
import { UpdateUser } from "Service/UserService";
import { useParams } from "react-router-dom";
// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { Fragment, useState } from "react";
import UserUpdates from "layouts/users/UserUpdates";
import { addVerification, removeVerification } from "Service/UserService";
import { redirectToAnotherURL } from "../../../../../src/layouts/users/userDetails";
function ProfileInfoCard({
  title,
  description,
  info,
  action,
  shadow,
  reload,
  profile_pic,
  is_verified,
}) {
  const labels = [];
  const values = [];
  const { size } = typography;

  // ProfileInfoCard.propTypes = {
  //   title: PropTypes.string.isRequired,
  //   description: PropTypes.string.isRequired,
  //   info: PropTypes.string.isRequired,
  //   action: PropTypes.string.isRequired,
  //   shadow: PropTypes.string.isRequired,
  //   // handleUpdate: PropTypes.func.isRequired, // Add this line for 'handleUpdate' prop
  // };

  const [opengroup, setOpenGroup] = useState(false);
  const [istrue, setIsTrue] = useState(false);
  const [userdata, setUserdata] = useState("");
  const { id } = useParams();

  const handleUpdate = (val) => {
    setAnchorEl(null);
    setOpenGroup(true);
    setIsTrue(true);
    setUserdata(val);
  };

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el
        .replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`)
        .replace(/_/g, " ");
      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
        {label == "connections" ? (
          <button
            onClick={() => redirectToAnotherURL()}
            type="button"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              marginLeft: "8px",
              padding: "1px 3px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            View list
          </button>
        ) : (
          <></>
        )}
      </MDTypography>
    </MDBox>
  ));
  const handleClose = () => {
    setOpenGroup(false);
    reload();
  };
  const [verifyConfirmModal, setVerifyConfirmModal] = useState(false);
  const handleCloseModal = () => {
    setVerifyConfirmModal(false);
  };
  const verifyAccount = async () => {
    try {
      const response = await addVerification(id);
      console.log(response);
      setVerifyConfirmModal(false);
      reload();
    } catch (error) {
      console.log(error);
    }
  };
  const removeVerificationAccount = async () => {
    try {
      const response = await removeVerification(id);
      console.log(response);
      setVerifyConfirmModal(false);
      reload();
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(true);
    }
  };
  const handleDelete = () => {
    setAnchorEl(null);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Remove Profile Pic",
        text: "Are you sure you want to remove Profile pic ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            const formData = new FormData();
            formData.append("Delete_profile_pic", true);
            UpdateUser({ data: formData, id: id })
              .then((res) => {
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Profile pic has been removed successfully.",
                  "success"
                );
                reload();
              })
              .catch((error) => {
                if (error.response) {
                  console.log("Error response data:", error.response.data.title);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    `${error.response.data.title}`,
                    "error"
                  );
                } else {
                  console.log("Network error:", error.message);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    "Profile pic is not deleted. Network error occurred.",
                    "error"
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDrop = () => {
    setAnchorEl(null);
  };
  const handleVerify = () => {
    setAnchorEl(null);
    setVerifyConfirmModal(true);
  };
  return (
    <Fragment>
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component={Link} variant="body2" color="secondary">
            <MoreVertIcon
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              fontSize="medium"
            >
              Edit
            </MoreVertIcon>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseDrop}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleUpdate(info)}>Edit User Data</MenuItem>
              {profile_pic ? (
                <MenuItem onClick={handleDelete}>Remove Profile Picture</MenuItem>
              ) : (
                <span></span>
              )}
              <MenuItem onClick={() => handleVerify()}>Verify User</MenuItem>
            </Menu>
          </MDTypography>
          <Dialog
            open={verifyConfirmModal}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {is_verified ? "Remove Verification" : "Add Verification"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to {is_verified ? "remove verification" : "add verification"}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={is_verified ? removeVerificationAccount : verifyAccount}>
                {is_verified ? "Remove" : "Add"}
              </Button>
              <Button onClick={handleCloseModal} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox p={2}>
          <MDBox mb={2} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox opacity={0.3}></MDBox>
          <MDBox>{renderItems}</MDBox>
        </MDBox>
      </Card>
      <UserUpdates opens={opengroup} onClose={handleClose} isTrue={istrue} data={userdata} />
    </Fragment>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    // route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  is_verified: PropTypes.bool,
  reload: PropTypes.func,
  profile_pic: PropTypes.string,
};

export default ProfileInfoCard;
