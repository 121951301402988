/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { getUserConnectionList } from "Service/UserService";
import { Card } from "react-bootstrap";
import user from "../../assets/images/output.png";
import { Pagination } from "@mui/material";
import { redirectToUser } from "./index";

function UserConnectionList() {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageLimit, setPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(0);
  const getConnectionList = async () => {
    try {
      const response = await getUserConnectionList({
        user_id: id,
        page: currentPage,
        limit: pageLimit,
      });
      setList(response.data);
      setTotalPage(Math.ceil(response.total_count / pageLimit));
      setCount(response.total_count);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePaginationChange = (event, page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getConnectionList();
  }, [currentPage]);
  const cards = {
    display: "flex",
    flexWrap: "wrap",
    padding: "15px",
  };
  const cardStyle = {
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    maxWidth: "300px",
    width: "250px",
    margin: "10px auto",
  };
  const imageStyle = {
    borderRadius: "50%",
    marginBottom: "10px",
  };

  const titleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
  };

  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="">
                    <MDTypography variant="h6" color="white">
                      Connections List
                    </MDTypography>
                  </div>
                </MDBox>
                <div className="" style={cards}>
                  {list.map((item) => (
                    <div
                      className=""
                      key={item.id}
                      style={cardStyle}
                      onClick={() => redirectToUser(item.id)}
                    >
                      <div className="image" style={{ display: "flex", justifyContent: "center" }}>
                        {item?.profile_pic != "" ? (
                          <img
                            alt="avatar"
                            src={`${process.env.REACT_APP_BASE_URL}${item?.profile_pic}`}
                            width="70px"
                            height="70px"
                            style={imageStyle}
                          />
                        ) : (
                          <img
                            alt="avatar"
                            src={user}
                            width="70px"
                            height="70px"
                            style={imageStyle}
                          />
                        )}
                      </div>
                      <div className="title" style={titleStyle}>
                        <span style={{ display: "flex", justifyContent: "center" }}>
                          {item?.name}
                        </span>
                      </div>
                    </div>
                  ))}
                  {count > list.length ? (
                    <div className="paginations_notify">
                      <Pagination
                        count={totalPage}
                        size="large"
                        page={currentPage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePaginationChange}
                      />
                    </div>
                  ) : null}
                </div>
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        <Footer />
      </DashboardLayout>
    </Fragment>
  );
}

export default UserConnectionList;
