import React, { useState, useEffect } from "react";
import BaseURL from "Baseurl";
import moment from "moment";
import "../notifications/notification.scss";
// import notify from "../../assets/img/bell.png";
import { CircularProgress, Pagination } from "@mui/material";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import notify from "../../assets/images/notify.svg";
import ReportIcon from "@mui/icons-material/Report";
import { Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import userimage from "../../assets/images/user.svg";
const Reports = () => {
  const [notification, setNotification] = useState([]);

  let token = localStorage.getItem("token");

  const [itemsPerPage, setItemPerPage] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const handleChange = (event, page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(itemsPerPage / pageLimit);

  const fetchReportedComments = async () => {
    setLoading(true);
    try {
      const response = await BaseURL.get(`admin/Report`, {
        params: {
          page: currentPage,
          limit: pageLimit,
          type: "comment",
        },
      });
      console.log("notification", response.data.data);
      setNotification(response.data?.data);
      setItemPerPage(response.data.total_count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const markAllAsRead = async () => {
    // try {
    //   await BaseURL.post(`api/wom/v2/admin/read_all_notification`);
    //   console.log("Marked all as read");
    //   window.location.reload(true);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    fetchReportedComments();
  }, [currentPage, pageLimit]);
  if (loading) {
    return (
      <CircularProgress
        size={60} // Adjust the size here
        style={{
          margin: "auto",
          display: "block",
          color: "#1A73E8",
          position: "relative",
          top: "20rem",
        }}
      />
    );
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div
                    className="group"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <MDTypography variant="h6" color="white">
                      Error Reporting
                    </MDTypography>
                  </div>
                </MDBox>
                <div className="notifications container">
                  <div className="title_notify">
                    <h3>
                      Reported Comments <ReportIcon />
                    </h3>
                    {/* <p onClick={markAllAsRead}>Mark all as read</p> */}
                  </div>
                  {notification.map((item) => (
                    <div className="cards_notification" key={item.id}>
                      <div className="user_det">
                        <div className="image" style={{ fontSize: "5rem" }}>
                          {item?.image ? (
                            <img alt="avatar" src={item?.image} width="70px" height="70px" />
                          ) : (
                            // <img alt="avatar" src={notify} width="70px" height="70px" />
                            <ReportIcon style={{ fontSize: "xxx-large" }} />
                          )}
                        </div>
                        <div className="title">
                          <span>{item?.title}</span>
                          <p>{item?.description}</p>
                          <br />
                        </div>
                      </div>
                      <div className="user_det">
                        <div className="userDetails">
                          <Link to={`/users/${item?.userDetails?.id ? item?.userDetails?.id : ""}`}>
                            {item?.userDetails?.profile_pic ? (
                              <MDAvatar
                                src={`${process.env.REACT_APP_BASE_URL}${item?.userDetails?.profile_pic}`}
                                alt="profile-image"
                                size="xl"
                                shadow="lg"
                                style={{ marginRight: "10px" }}
                              />
                            ) : (
                              <img alt="avatar" src={userimage} width="70px" height="70px" />
                            )}
                          </Link>
                          <span>{item?.userDetails?.name}</span>
                          <br />
                        </div>
                      </div>
                    </div>
                  ))}
                  {notification.length > 5 ? (
                    <div className="paginations_notify">
                      <Pagination
                        count={totalPages}
                        size="large"
                        page={currentPage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                      />
                    </div>
                  ) : null}
                </div>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
};

export default Reports;
