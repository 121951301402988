/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import "./notification.scss";
import "../tables/table.css";
import { getAllUsers } from "Service/UserService";
import BaseURL from "Baseurl";
import Select from "react-select";
import { toast } from "react-toastify";

function Notifications() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [user_id, setUser_id] = useState("");
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const [notifyType, setNotifyType] = useState();

  console.log("user", user_id);

  const notify = () => toast("Notification Sent Successfully!");

  const [shopSearchResult, setShopSearchResult] = useState([]);
  const [shopSearchKey, setShopSearchKey] = useState("");
  const [loadingshopSearch, setLoadingShopSearch] = useState(false);

  const shopSearchData = async () => {
    setLoadingShopSearch(true);
    try {
      const res = await getAllUsers(1, 1000);
      let _tempData = res?.data ?? [];
      let _sortedData = _tempData.map((e) => {
        return {
          value: e.id,
          label: e.name,
        };
      });

      setShopSearchResult(_sortedData);
    } catch (error) {
      console.error("Error in shopSearchData:", error);
      // Handle the error as needed, e.g., display an error message to the user.
    } finally {
      setLoadingShopSearch(false);
    }
  };

  useEffect(() => {
    shopSearchData();
  }, [shopSearchKey]);

  const renderErrorMessage = (fileRejections) => {
    if (fileRejections.length > 0) {
      const fileSizeExceeded = fileRejections.some(
        (rejection) => rejection.file.size > 2 * 1024 * 1024
      );

      if (fileSizeExceeded) {
        return "File size should not exceed 2MB.";
      }
    }
    return "";
  };

  const Notification = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!title) {
      errors.title = "Title is required";
    }
    if (!description) {
      errors.description = "Description is required";
    }

    if (Object.keys(errors).length === 0) {
      try {
        await BaseURL.post(`Notification/insertNotification`, {
          title,
          body: description,
          userId: `${user_id}`,
        });
        setTitle("");
        setDescription("");
        setUser_id("");
        setErrors("");
        setLoading(true);
        notify();
      } catch (error) {
        console.log(error);
      }
    }
    setErrors(errors);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Remove the error message for the corresponding field
    }));

    // Update the state variable with the new input value
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <div className="container notify">
          <form onSubmit={Notification}>
            <div className="row d-flex justify-content-center">
              <div className="col-md-8">
                <div className="inputs">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="title"
                    className="form-control"
                    name="title"
                    value={title}
                    onChange={handleInputChange}
                  />
                  {errors.title && <p className="error">{errors.title}</p>}
                </div>
                <div className="inputs">
                  <label>description</label>
                  <textarea
                    type="text"
                    placeholder="description"
                    className="form-control"
                    rows="3"
                    name="description"
                    value={description}
                    onChange={handleInputChange}
                  />
                  {errors.description && <p className="error">{errors.description}</p>}
                </div>
                <div className="inputs">
                  <label>User Id</label>
                  <Select
                    closeMenuOnSelect={true}
                    options={shopSearchResult}
                    name="user"
                    className="form-control"
                    isSearchable={true}
                    isLoading={loadingshopSearch}
                    onInputChange={(shopSearchKey) => setShopSearchKey(shopSearchKey)}
                    onChange={(selectedOption) => {
                      setUser_id(selectedOption.value);
                      console.log(selectedOption.value);
                    }}
                  />
                  {errors.category_user && <p className="error">{errors.category_user}</p>}
                </div>
                <div className="buttions">
                  <button className="btn btn-primary" type="submit">
                    Send Notification To User
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default Notifications;
