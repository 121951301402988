// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//react
import "react-data-table-component-extensions/dist/index.css";
import "react-data-table-component-extensions/dist/index.css";

// Data
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types"; // Import PropTypes
import { Fragment, useEffect, useState } from "react";
import GroupService from "Service/Group/GroupService";
import "../tables/table.css";

import noimage from "../../assets/images/noimagepng.png";
import deleteIcons from "../../assets/images/delete.png";
import EditIcon from "@mui/icons-material/Edit";
import AdminEditModel from "./AdminEditModel";

import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Swal from "sweetalert2";
import BaseURL from "Baseurl";
import { useParams } from "react-router-dom";
import { getAdmin } from "Service/AdminService";
import { useNavigate } from "react-router-dom";

function AdminDetailsPage() {
  const navigate = useNavigate();

  const { id } = useParams();

  const handleDelete = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Admin?",
        text: "Are you sure you want to delete this Admin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`admin/User/${id}`, {
              id: id,
            }).then((res) => {
              navigate("/admin");
              console.log("res", res.data);
            });
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Admin has been deleted successfully.",
            "success"
          );
        }
      });
  };

  const baseURL = process.env.REACT_APP_BASE_URL;

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired, // Make sure to adjust the type as needed
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  Job.propTypes = {
    title: PropTypes.string.isRequired, // Make sure to adjust the type as needed
  };
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [groupdata, setGroupData] = useState("");

  const getUsers = async () => {
    try {
      const response = await getAdmin(id).then((res) => {
        console.log("res", res.data);
        setGroupData(res.data);
      });
      if (response?.data && response?.data?.length > 0) {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [opencategory, setopenCategory] = useState(false);
  const [istrue, setIsTrue] = useState("");
  const [groupDataEdit, setGroupDataEdit] = useState(null);
  const handleClickCategory = (item) => {
    setopenCategory(true);
    setGroupDataEdit(item);
    setIsTrue(true);
  };
  useEffect(() => {
    getUsers();
  }, []);

  const [memberData, setMemberData] = useState([]);

  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div
                    className="group"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <MDTypography variant="h6" color="white">
                      Admin Details
                    </MDTypography>
                  </div>
                </MDBox>
                <div className="group_details p-5 row">
                  <div className="col-md-3">
                    <div className="cover_image">
                      {groupdata?.profile_pic ? (
                        <img
                          src={`${baseURL}${groupdata?.profile_pic}`}
                          alt="image"
                          height="180px"
                          width="180px"
                        />
                      ) : (
                        <img src={noimage} alt="image" width="100px" height="100px" />
                      )}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="group_det">
                      <p>Name</p> :<span>{groupdata?.name}</span>
                    </div>
                    <div className="group_det">
                      <p>About</p> :<span>{groupdata?.about}</span>
                    </div>
                    <div className="group_det">
                      <p>Email</p> :<span>{groupdata?.email}</span>
                    </div>
                    <div className="group_det">
                      <p>National Id</p> :<span>{groupdata?.nationaL_ID}</span>
                    </div>
                    <div className="group_det">
                      <p>country</p> :<span>{groupdata?.country}</span>
                    </div>
                    <div className="group_det">
                      <p>Preferred Lang</p> :<span>{groupdata?.preferred_lang}</span>
                    </div>
                    <div className="group_det">
                      <p>Position</p> :<span>{groupdata?.position}</span>
                    </div>
                    <div className="group_det">
                      <p>User Role</p> :<span>{groupdata?.user_role}</span>
                    </div>
                    <div className="group_det">
                      <p>Organaization</p> :<span>{groupdata?.organaization}</span>
                    </div>
                    <div className="group_det">
                      <p>Organization Branch</p> :<span>{groupdata?.organization_branch}</span>
                    </div>
                    <div className="group_det">
                      <p>Organization Location</p> :<span>{groupdata?.organization_location}</span>
                    </div>
                  </div>
                  <div className="col-md-2 d-flex flex-column">
                    <OverlayTrigger overlay={<Tooltip> Edit</Tooltip>}>
                      <EditIcon
                        onClick={() => handleClickCategory(groupdata)}
                        sx={{ fontSize: 20, marginLeft: "10px", marginBottom: "10px" }}
                      />
                    </OverlayTrigger>
                    <img src={deleteIcons} alt="delete" width="35px" onClick={handleDelete} />
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <AdminEditModel
        opens={opencategory}
        data={groupDataEdit}
        id={id}
        isTrue={istrue}
        onUpdate={() => getUsers()}
        onClose={() => setopenCategory(false)}
      />
    </Fragment>
  );
}

export default AdminDetailsPage;
