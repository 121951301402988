/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { Fragment, useState } from "react";
import UserUpdates from "layouts/users/UserUpdates";
import { redirectToAnotherURL } from "../../../../layouts/users/userDetails";
import { OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";
import "./eventinfo.css";
import moment from "moment";

function EventInfoCard({ title, description, info, action, shadow, eventMembers }) {
  const labels = [];
  const values = [];
  const { size } = typography;

  EventInfoCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    shadow: PropTypes.string.isRequired,
    handleUpdate: PropTypes.func.isRequired, // Add this line for 'handleUpdate' prop
    eventMembers: PropTypes.Object,
  };

  const [opengroup, setOpenGroup] = useState(false);
  const [istrue, setIsTrue] = useState(false);
  const [userdata, setUserdata] = useState("");

  const handleUpdate = (val) => {
    setOpenGroup(true);
    setIsTrue(true);
    setUserdata(val);
  };

  const showMembers = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Event Members",
        html: eventMembers
          .map(
            (item, id) =>
              `<div class='members-block'>
              <label class='event-members-item' onclick="window.location.href = '/users/${
                item.user.id
              }'">${id + 1} - ${item.user.name}</label>
              <span>${item?.joined}</span></div>`
          )
          .join(" "),
      })
      .then(async (result) => {});
    console.log(eventMembers);
  };

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el
        .replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`)
        .replace(/_/g, " ");
      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });
  console.log("cc", labels);

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
        {label == "geolocation" ? (
          <button
            onClick={() => window.open("https://maps.google.com/?q=" + values[key])}
            type="button"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              marginLeft: "8px",
              padding: "1px 3px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Show on Map
          </button>
        ) : (
          <></>
        )}
        {label == "volunteer count" && values[key] > 0 ? (
          <button
            onClick={showMembers}
            type="button"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              marginLeft: "8px",
              padding: "1px 3px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Show Members
          </button>
        ) : (
          <></>
        )}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Fragment>
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDBox mb={2} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox opacity={0.3}>
            <Divider />
          </MDBox>
          <MDBox>{renderItems}</MDBox>
        </MDBox>
      </Card>
      <UserUpdates
        opens={opengroup}
        onClose={() => setOpenGroup(false)}
        isTrue={istrue}
        data={userdata}
      />
    </Fragment>
  );
}

// Setting default props for the EventInfoCard
EventInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the EventInfoCard
EventInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  shadow: PropTypes.bool,
};

export default EventInfoCard;
