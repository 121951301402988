import BaseURL from "Baseurl";

const getAll = ({ page, limit }) => {
  const hasToken = localStorage.getItem("token");
  if (!hasToken) return;
  return BaseURL.get(`admin/Group/getAll?page=${page}&limit=${limit}`);
};

const get = (id) => {
  return BaseURL.put(`admin/Group/${id}`);
};

const create = (data) => {
  return BaseURL.postForm(`/admin/Group`, data);
};

const update = (data, id) => {
  return BaseURL.putForm(`/admin/Group/${id}`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`admin/Group/${_id}`);
};

const GroupService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default GroupService;
