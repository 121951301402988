/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BaseURL from "Baseurl";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function ResetPassword() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");

  const [password, setpassword] = useState("");
  const [otp, setOtp] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    BaseURL.post(`/User/resetPassword`, {
      otp,
      password,
      email,
    })
      .then((response) => {
        setEmail("");
        setpassword("");
        setOtp("");
        if (response.data) {
          toast.success("Reset password Success");
        }
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data) {
          toast.error(err.response?.data);
        } else {
          toast.error("Invalid Otp");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput
                type="number"
                label="OTP"
                variant="standard"
                fullWidth
                required
                onChange={(e) => setOtp(e.target.value)}
              />
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                required
                onChange={(e) => setpassword(e.target.value)}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" type="submit" color="error" fullWidth>
                {loading ? <CircularProgress color="inherit" size={20} /> : "Submit"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;
