import React, { useState, useEffect } from "react";
import BaseURL from "Baseurl";
import moment from "moment";
import "./notification.scss";
// import notify from "../../assets/img/bell.png";
import { CircularProgress, Pagination } from "@mui/material";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import userimage from "../../assets/images/user.svg";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import notify from "../../assets/images/notify.svg";

const NotifyList = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [notification, setNotification] = useState([]);

  let token = localStorage.getItem("token");

  const [itemsPerPage, setItemPerPage] = useState("");

  const pageLimit = 50;

  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, page) => {
    setCurrentPage(page);
  };
  const [loading, setLoading] = useState(false);

  const totalPages = Math.ceil(itemsPerPage / pageLimit);

  console.log("totalRows", totalPages);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await BaseURL.get(`Notification/admin/get_notifications`, {
        params: {
          page: currentPage,
          limit: pageLimit,
        },
      });
      console.log("notification", response.data.data);
      setNotification(response.data?.data);
      setItemPerPage(response.data.total_count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const markAllAsRead = async () => {
    // try {
    //   await BaseURL.post(`api/wom/v2/admin/read_all_notification`);
    //   console.log("Marked all as read");
    //   window.location.reload(true);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    fetchNotifications();
  }, [currentPage, pageLimit]);
  if (loading) {
    return (
      <CircularProgress
        size={60} // Adjust the size here
        style={{
          margin: "auto",
          display: "block",
          color: "#1A73E8",
          position: "relative",
          top: "20rem",
        }}
      />
    );
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div
                    className="group"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <MDTypography variant="h6" color="white">
                      Notification List
                    </MDTypography>
                  </div>
                </MDBox>
                <div className="notifications container">
                  <div className="title_notify">
                    <h3>
                      Notification <NotificationsIcon />
                    </h3>
                    <p onClick={markAllAsRead}>Mark all as read</p>
                  </div>
                  {notification.map((item) => (
                    <div className="notifyx" key={item.id}>
                      <div className="cards_notification">
                        <div className="user_det">
                          <div className="image">
                            {item?.image ? (
                              <img alt="avatar" src={item?.image} width="70px" height="70px" />
                            ) : (
                              <img alt="avatar" src={notify} width="70px" height="70px" />
                            )}
                          </div>
                          <div className="title">
                            <span>{item?.title}</span>
                            <p>{item?.notification_type}</p>
                            <p>{item?.body}</p>
                            <small>{moment(item?.created).format("MMMM Do YYYY, h:mm:ss a")}</small>
                            <br />
                            <small>{moment(item?.created).fromNow()}</small>
                          </div>
                        </div>
                        <div className="user_det">
                          <div className="image">
                            {!item?.user_pic ? (
                              <img alt="avatar" src={userimage} width="70px" height="70px" />
                            ) : (
                              <img
                                alt="avatar"
                                src={`${baseURL}${item.user_pic}`}
                                width="70px"
                                height="70px"
                              />
                            )}
                          </div>
                          <div className="title">
                            <span>{item?.notification_user_name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {notification.length > 5 ? (
                    <div className="paginations_notify">
                      <Pagination
                        count={totalPages}
                        size="large"
                        page={currentPage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                      />
                    </div>
                  ) : null}
                </div>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
};

export default NotifyList;
