import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Fragment, useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { getArticleDetailsData, getArticleCommentData } from "../../Service/Article/ArticleService";
import MDBox from "components/MDBox";
import { Card, CardContent, CardHeader, Avatar, Typography, Button } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import PropTypes from "prop-types";
import { Box, Grid, List, ListItem, Container } from "@mui/material";
import user from "../../assets/images/output.png";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import moment from "moment";
import Swal from "sweetalert2";
import BaseURL from "Baseurl";

const ArticleDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [positionLat, setPositionLat] = useState(0);
  const [positionLong, setPositionLong] = useState(0);
  const [keyProp, setKeyProp] = useState([positionLat, positionLong]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [file, setFile] = useState([]);
  const [slicePart, setSlicePart] = useState(4);
  const [comments, setComments] = useState([]);
  const [showAllComments, setShowAllComments] = useState(false);
  const displayedComments = showAllComments ? comments : comments.slice(0, 5);

  const dep = useMemo((keyProp) => {
    keyProp;
  }, []);

  const getArticleDetails = async () => {
    try {
      const response = await getArticleDetailsData(id);
      setData(response.data);
      setFile(response.data.files);
      setPositionLat(response.data.location_lat);
      setPositionLong(response.data.location_long);
      setKeyProp([positionLat, positionLong]);
    } catch (error) {
      console.log(error);
    }
  };
  const getArticleComment = async () => {
    const response = await getArticleCommentData(id);
    setComments(response);
  };
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
  const makeFull = () => {
    setSlicePart(file.length);
  };
  const handleDelete = (comment_id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Comment?",
        text: "Are you sure you want to delete this Comment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            BaseURL.delete(`admin/Article/comment/${comment_id}`)
              .then((res) => {
                getArticleComment();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Comment has been deleted successfully.",
                  "success"
                );
              })
              .catch((error) => {
                if (error.response) {
                  console.log("Error response data:", error.response.data.title);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    `${error.response.data.title}`,
                    "error"
                  );
                } else {
                  console.log("Network error:", error.message);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    "Post is not deleted. Network error occurred.",
                    "error"
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  };

  useEffect(() => {
    getArticleDetails();
    getArticleComment();
  }, [dep, positionLat, positionLong]);
  return (
    <Fragment>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox>
          <a
            href={`${process.env.REACT_APP_BASE_URL}${data?.coverImage}`}
            target="_blank"
            rel="noopener noreferrer"
          ></a>
          <MDBox mt={5} mb={3}>
            <MDBox
              display="flex"
              alignItems="center"
              position="relative"
              minHeight="18.75rem"
              borderRadius="xl"
              backgroundColor="red"
              sx={{
                backgroundImage: ({
                  functions: { rgba, linearGradient },
                  palette: { gradients },
                }) =>
                  `${linearGradient(
                    rgba(gradients.info.main, 0),
                    rgba(gradients.info.state, 0)
                  )}, url(${process.env.REACT_APP_BASE_URL}${data?.coverImage})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "hidden",
              }}
            />
            <Card style={{ margin: "-40px 40px auto" }}>
              <CardHeader
                avatar={
                  <Link to={`/users/${data?.userDetails?.id}`} style={{ "margin-bottom": "auto" }}>
                    <Avatar
                      alt="User Photo"
                      src={`${process.env.REACT_APP_BASE_URL}${data?.userDetails?.profile_pic}`}
                      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                    />
                  </Link>
                }
                title={data?.userDetails?.name}
              />
              <CardContent>
                <Typography variant="h4" component="h2">
                  {data?.title}
                </Typography>
              </CardContent>
              <Card>
                <CardContent style={{ display: "flex" }}>
                  <div style={{ paddingRight: "30px" }}>
                    <CommentIcon color="primary" style={{ marginRight: "5px" }} />
                    <Typography variant="body3" color="textSecondary">
                      Comments: {data?.noOfComments}
                    </Typography>
                  </div>
                  <div style={{ paddingRight: "30px" }}>
                    <ThumbUpIcon color="primary" style={{ marginRight: "5px" }} />
                    <Typography variant="body3" color="textSecondary">
                      Likes: {data?.noOfLikes}
                    </Typography>
                  </div>
                  <div style={{ paddingRight: "30px" }}>
                    <VisibilityIcon color="primary" style={{ marginRight: "5px" }} />
                    <Typography variant="body3" color="textSecondary">
                      Views: {data?.noOfViews}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 10px",
                }}
              >
                <Typography variant="body2" component="h2" style={{ flex: 1, padding: "10px" }}>
                  {data?.content}
                </Typography>
                {data?.location ? (
                  <MyMapComponent keyProp={keyProp} index={1} data={data} style={{ flex: 1 }} />
                ) : (
                  <></>
                )}
              </div>
              <div style={{ margin: "2px", marginTop: "20px" }}>
                {data?.likes?.map((profilePic, index) => (
                  <img
                    key={index}
                    src={profilePic != "" ? `${process.env.REACT_APP_BASE_URL}${profilePic}` : user}
                    alt={`Liked by user ${index + 1}`}
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  />
                ))}
              </div>
            </Card>
          </MDBox>
          <Card
            sx={{ mt: 3, mx: 3, py: 2, px: 2, mb: 3 }}
            style={{ maxHeight: "600px", overflowY: " overlay", cursor: "default" }}
          >
            <MDBox pt={2} px={2} lineHeight={1.25}>
              <MDTypography variant="h6" fontWeight="medium">
                Comments
              </MDTypography>
            </MDBox>
            <Container className="container" style={{ "min-width": "-moz-available" }}>
              <List>
                {displayedComments && displayedComments.length > 0 ? (
                  displayedComments.map((comment, index) => (
                    <ListItem key={index} style={{ marginBottom: "20px" }}>
                      <Link
                        to={`/users/${comment?.userDetails?.id}`}
                        style={{ "margin-bottom": "auto" }}
                      >
                        <MDAvatar
                          src={`${process.env.REACT_APP_BASE_URL}${comment?.userDetails?.profile_pic}`}
                          alt="profile-image"
                          size="m"
                          shadow="sm"
                          sx={{ mt: 2 }}
                        />
                      </Link>
                      <MDBox height="100%" mt={1} mx={1} lineHeight={1} sx={{ display: "grid" }}>
                        <MDTypography variant="button" fontWeight="medium" fontSize="12px">
                          {comment?.userDetails?.name}
                          <MDTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            fontSize="12px"
                            sx={{ marginLeft: "10px" }}
                          >
                            <small>
                              on {""} {moment(comment?.created_at).format("MMMM DD YYYY")}
                            </small>
                          </MDTypography>
                        </MDTypography>
                        <MDTypography
                          variant="button"
                          color="text"
                          fontWeight="regular"
                          fontSize="12px"
                        >
                          {comment?.comments}
                        </MDTypography>
                        <div>
                          <DeleteIcon
                            onClick={() => handleDelete(comment?.id)}
                            sx={{ fontSize: 10, cursor: "pointer", width: "0.8em" }}
                            style={{ marginRight: "10px" }}
                          />
                          <FavoriteIcon sx={{ fontSize: 10, cursor: "pointer", width: "0.8em" }} />
                          <span
                            style={{ fontWeight: "100", fontSize: "medium", marginLeft: "2px" }}
                          >
                            {comment?.like_count}
                          </span>
                        </div>
                        <div>
                          {comment?.replies?.map((subComment) => (
                            <ListItem key={index}>
                              <Link to={`/users/${subComment?.userDetails?.id}`}>
                                <MDAvatar
                                  src={`${process.env.REACT_APP_BASE_URL}${subComment?.userDetails?.profile_pic}`}
                                  alt="profile-image"
                                  size="m"
                                  shadow="sm"
                                  sx={{ mt: 2 }}
                                />
                              </Link>
                              <MDBox
                                height="100%"
                                mt={1}
                                mx={1}
                                lineHeight={1}
                                sx={{ display: "grid" }}
                              >
                                <MDTypography variant="button" fontWeight="medium" fontSize="12px">
                                  {subComment?.userDetails?.name}
                                  <MDTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    fontSize="12px"
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <small>
                                      on {""}{" "}
                                      {moment(subComment?.created_at).format("MMMM DD YYYY")}
                                    </small>
                                  </MDTypography>
                                </MDTypography>
                                <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="regular"
                                  fontSize="12px"
                                >
                                  {subComment?.comments}
                                </MDTypography>
                                <div>
                                  <DeleteIcon
                                    onClick={() => handleDelete(subComment?.id)}
                                    sx={{ fontSize: 10, cursor: "pointer", width: "0.8em" }}
                                    style={{ marginRight: "10px" }}
                                  />
                                  <FavoriteIcon
                                    sx={{ fontSize: 10, cursor: "pointer", width: "0.8em" }}
                                  />
                                  <span
                                    style={{
                                      fontWeight: "100",
                                      fontSize: "medium",
                                      marginLeft: "2px",
                                    }}
                                  >
                                    {subComment?.like_count}
                                  </span>
                                </div>
                              </MDBox>
                            </ListItem>
                          ))}
                        </div>
                      </MDBox>
                    </ListItem>
                  ))
                ) : (
                  <MDTypography variant="button" color="text" fontWeight="regular" fontSize="12px">
                    No comments
                  </MDTypography>
                )}
              </List>
              {comments.length > 5 && (
                <Button variant="text" onClick={() => setShowAllComments(!showAllComments)}>
                  {showAllComments ? "See Less" : "See More"}
                </Button>
              )}
            </Container>
          </Card>
          {file.length > 0 && file[0] != "" ? (
            <Card style={{ margin: "0px 40px auto" }}>
              <CardHeader title="Files" />
              {file.length > 4 ? <Button onClick={() => makeFull()}>View All</Button> : <></>}
              <CardContent>
                <Box>
                  <Grid
                    spacing={2}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    {file.slice(0, slicePart).map((item, index) => (
                      <Grid item xs={8} key={index} style={{ margin: "10px 10px" }}>
                        <img
                          key={index}
                          src={`${process.env.REACT_APP_BASE_URL}${item}`}
                          alt={item}
                          style={{ width: "300px", height: "300px", objectFit: "cover" }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          ) : (
            <></>
          )}
        </MDBox>
        <Footer />
      </DashboardLayout>
    </Fragment>
  );
};

const Recenter = ({ keyProp }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([keyProp[0], keyProp[1]]);
  }, [keyProp[0], keyProp[1]]);
  return null;
};
function MyMapComponent({ keyProp, index, data }) {
  return (
    <MapContainer key={index} center={keyProp} zoom={10} style={{ height: "300px", width: "30%" }}>
      <Recenter keyProp={[keyProp[0], keyProp[1]]} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={keyProp}>
        <Popup>{data?.location}</Popup>
      </Marker>
    </MapContainer>
  );
}

MyMapComponent.propTypes = {
  keyProp: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  // Change the prop type to match your actual prop type
};
Recenter.propTypes = {
  keyProp: PropTypes.array.isRequired,
  // Change the prop type to match your actual prop type
};

export default ArticleDetails;
