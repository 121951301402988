import React, { Fragment } from "react";
import { Col, Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
// const Error404 = () => {
document.querySelector("body").classList.add("error-1");
//   return (

//   );
// };

// Error404.propTypes = {};

// Error404.defaultProps = {};

// export default Error404;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";

function PlainInfo() {
  return (
    <PageLayout>
      <Fragment>
        {/* <!-- Page --> */}
        <div className="ltr main-body leftmenu">
          <div className="page main-signin-wrapper construction" style={{ height: "100vh" }}>
            <Container
              className="text-center"
              style={{ margin: 0, maxWidth: "100%", paddingTop: "10%" }}
            >
              <div className="construction1 text-center details">
                <div>
                  <Col lg={12}>
                    {/* <h1>Oops.The Page you are looking for doesn't exit..</h1> */}
                    <h6 className="tx-15 mt-3 mb-4 text-black-50">V-1.0.0</h6>
                    {/* <Link to={`/dashboard`} className="btn ripple btn-success text-center mb-2">
                      Back to Home
                    </Link> */}
                  </Col>
                </div>
              </div>
            </Container>
          </div>
        </div>
        {/* <!-- End Page - */}
      </Fragment>
    </PageLayout>
  );
}

export default PlainInfo;
